import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import api from '../../api';
import { useNavigate } from 'react-router-dom';

export const FilterComp = () => {
    const navigate = useNavigate()

    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [loader, setLoader] = useState(false);
    const [formDataVal, setFormDataVal] = useState({});
    const [condition, setCondition] = useState("all");

    const getMakers = async()=>{
        try {
        
          setLoader(true)
          const postData = await api.getAllMake()
          // console.log(postData);
          if(postData.success){
            let data = postData.make.map((d)=> (
              {value:d.name,label:d.name}
            ));
            // console.log(data);
            setMakers(data)
          }else {
          alert.show("Something went wrong.")
          }
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const getModels = async()=>{
        try {
          setLoader(true)
          const postData = await api.getAllModels()
          // console.log(postData);
          let data = postData.model.map((d)=> (
            {value:d.model,label:d.model}
          ));
          setModels(data)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const handleData = (e,type) => {
        // console.log(e);
        if(type){
          setFormDataVal({...formDataVal,[type]:e})
        }else{
          setFormDataVal({...formDataVal,[e.target.name]:e.target.value})
        }
      } 

      const handleSubmit = () => {
        // console.log(formDataVal);
        // console.log(Object.keys(formDataVal));
        if(Object.keys(formDataVal).length >0){
          let payload = {...formDataVal}
          if(condition == "new" || condition == "used"){
            payload.condition = condition
          }
            let queryString = objectToQueryString(payload)
            navigate(`/stock/pakistan/?${queryString}`)
        }
      }

      const objectToQueryString = (dataObject) => {
        let queryString = Object.keys(dataObject).map((key) => { 
          if (Array.isArray(dataObject[key])) { 
            return dataObject[key].map((item, index) => { 
              return encodeURIComponent(key) + '[' + index + ']=' + encodeURIComponent(item); }).join('&');
          } else { 
            return encodeURIComponent(key) + '=' + encodeURIComponent(dataObject[key])
          }
        }).join('&');
        return queryString
      }
      
    useEffect(() => {
      getMakers()
      getModels()
    }, [])
        

  return (
    <div className="container">
        <section className='filterMain'>
            <div className="btnMain">
                <button className={condition == "all" ? 'active':""} onClick={()=>setCondition("all")}>All conditions</button>
                <button className={condition == "new" ? 'active':""} onClick={()=>setCondition("new")}>New</button>
                <button className={condition == "used" ? 'active':""} onClick={()=>setCondition("used")}>Used</button>
                {/* <button onClick={()=>setCondition("local")}>Local</button>
                <button onClick={()=>setCondition("imported")}>Imported</button> */}
            </div>
            <div className="filterRow">
                <div className="form-group">
                    <Select
                        className="basic-single input"
                        classNamePrefix="select"
                        // defaultValue={optionMake[0]}
                        // isLoading={isLoading}
                        // isClearable={isClearable}
                        isRtl={false}
                        isSearchable={true}
                        name="make"
                        options={makers}
                        placeholder={"Make..."}
                        onChange={(e)=>handleData(e.value,"make")}
                        />
                </div>
                <div className="form-group">
                    <Select
                        className="basic-single input"
                        classNamePrefix="select"
                        // defaultValue={optionModel[0]}
                        // isLoading={isLoading}
                        // isClearable={isClearable}
                        isRtl={false}
                        isSearchable={true}
                        name="model"
                        options={models}
                        placeholder={"Model..."}
                        onChange={(e)=>handleData(e.value,"model")}
                    />
                </div>
                <div className="form-group">
                    <input onChange={(e)=>handleData(e)} name='maxPrice' className='input' type="text" placeholder='Max Price' min={0} />
                </div>
                <button className="themeBtn" onClick={handleSubmit}>
                    <i className="fa-solid fa-magnifying-glass mr-2"></i>
                    Search</button>
            </div>
        </section>
    </div>
  )
}

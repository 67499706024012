import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import plus from "../../assets/images/plus.svg";
import upload from "../../assets/images/uploadIco.svg";
import api from '../../api';
import { useAlert } from 'react-alert';
import { Pagination, Stack } from '@mui/material';
import { LoaderComp } from '../../component/loader/loader';


function ReviewAdmin({}) {
  const alert = useAlert()
  const [selectedFile, setSelectedFile] = useState(null);
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formDesc, setFormDesc] = useState("");
  const [formRating, setFormRating] = useState("");
  const [formLogo, setFormLogo] = useState(null);
  const [noOfMake, setnoOfMake]=useState(0)
  const [data, setData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getReviews = async()=>{
    try {
  
      setLoader(true)
      const postData = await api.getReview()
      // console.log(postData);
      setData(postData.review)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleChangeLogo = (e) => {
    if(e.target.files[0].size > 200000){
      alert.show("You can upload max 200kb file")
    }
    else{
      setFormLogo(e.target.files[0])
    }
  }
  const checkValidation = () => {
    if(!formTitle) {
      return "Please insert Title"
    }
    if(!formDesc) {
      return "Please insert Title"
    }
    if(!formLogo) {
      return "Please add company logo"
    }
    return false
  }

  const handleSubmit = async() => {
    try {      
      let checks = checkValidation()
      if(checks){
        return alert.show(checks)
      }
      setLoader(true)
      
      const formData = new FormData();
      formData.append("title", formTitle);
      formData.append("description", formDesc);
      formData.append("rating", formRating);
      formData.append("image", formLogo, formLogo.name);
  
      const response = await api.addReview(formData);
      // console.log(response);
      
      handleClose();
      getReviews();
      alert.show("Review successfully uploaded.")
      setFormLogo(null)
      setFormTitle("")
      setFormDesc("")
      setFormRating("")
      setLoader(false)
    } catch (error) {
        alert.show("Something went wrong.")
        setLoader(false)
    }
  }  

  const deletePost = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.deleteReview(payload)
      console.log("deleted");
      // console.log(userData);
      alert.show("Review deleted.")
      getReviews()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }


  useEffect(() => {
    getReviews()
  
  }, [page])
  
    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Reviews ({noOfMake})</h4>
                             </div>
                         </div>
                         {/* table */}
                         <Table noOfMake={noOfMake} data={data} page={page} limit={limit} deletePost={deletePost} />
                         <div onClick={handleShow} className="diverAddBtn"><img src={plus} alt="" /></div>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className="verhicleForm">
                        <div className="closeIco" onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="head">Add Review Info</div>
                        <div className="form">
                            <div className="form-group">
                                <input onChange={(e) => setFormTitle(e.target.value)} value={formTitle} type="text" className="form-control border-0" placeholder='Title...'  />
                            </div>
                            <div className="form-group">
                                <input onChange={(e) => setFormDesc(e.target.value)} value={formDesc} type="text" className="form-control border-0" placeholder='Description...'  />
                            </div>
                            <div className="form-group">
                                <input onChange={(e) => setFormRating(e.target.value)} value={formRating} type="number" className="form-control border-0" placeholder='Rating...'  />
                            </div>
                            <div className="bb1pxLight"></div>
                            <div className="addLogo">
                                <div className="title">Attach Logo</div>
                                <label>
                                  {formLogo ? 
                                    <img src={URL.createObjectURL(formLogo)} alt="upload" />
                                  :
                                    <img src={upload} alt="upload" />
                                  }
                                    <input onChange={(e) => handleChangeLogo(e)} type="file" name="logo" accept="image/*" />
                                </label>
                            </div>
                            <div className='mt-4'><button className="themeBtn w-100" onClick={handleSubmit} >Submit</button></div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {loader &&
            <LoaderComp />
            }
                                  
      </>
    )
  }
  const Table=({data,deletePost})=>{
    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table class="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">#</th>
                  <th scope="col" className="text-uppercase">Thumbnail</th>
                  <th scope="col" className="text-uppercase">Title</th>
                  <th scope="col" className="text-uppercase">Description</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row,i)=>(

                <tr>
                  <td>
                    <span className="table-text-dark w-max-c db">{i + 1}</span>
                  </td>
                  <td scope="row" className="d-flex">
                        <div className="maker-logo">
                            <img 
                            src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${row.logo}`} 
                            alt="" />
                        </div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row.title}</span>
                  </td>
                  <td>
                    <span className="table-text-dark max-width-400">{row.description}</span>
                  </td>
                  <td>
                    <div className="mr-5">
                      {/* <a href="#" className="mr-3"><img src={edit} alt="edit" /></a> */}
                      <a href="#" onClick={()=>deletePost(row?._id)}><img src={Delete} alt="Delete" /></a>
                    </div>
                  
                  </td>
                </tr>
                ))}
              
            
              </tbody>
            </table>
          </div>
      </div>

    </div>
    )
  }

  export default ReviewAdmin
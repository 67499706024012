import car1 from "../assets/images/car1.jpg";
import car2 from "../assets/images/car2.jpg";
import car3 from "../assets/images/car3.jpg";
import car4 from "../assets/images/car4.jpg";

export const optionMake = [
    {value:"toyota",label:"Toyota"},
    {value:"honda",label:"Honda"},
    {value:"suzuki",label:"Suzuki"},
    {value:"kia",label:"Kia"},
    {value:"hyundai",label:"Hyundai"},
]
export const optionActive = [
    {value:true,label:"Active"},
    {value:false,label:"In-Active"},
]
export const optionModel = [
    {value:"raize",label:"raize"},
    {value:"passo",label:"passo"},
    {value:"corolla",label:"corolla"},
    {value:"yaris",label:"yaris"},
    {value:"prodo",label:"prodo"},
    {value:"landcruiser",label:"landcruiser"},
    {value:"camry",label:"camry"},
    {value:"city",label:"city"},
    {value:"civic",label:"civic"},
    {value:"freed",label:"freed"},
    {value:"sportage",label:"sportage"},
    {value:"picanto",label:"picanto"},
    {value:"stonic",label:"stonic"},
    {value:"sorento",label:"sorento"},
    {value:"carnival",label:"carnival"},
    {value:"alto",label:"alto"},
    {value:"cultus",label:"cultus"},
    {value:"ciaz",label:"ciaz"},
    {value:"wagonr",label:"wagonr"},
]
export const optionCondition = [
    {value:"new",label:"New"},
    {value:"used",label:"Used"},
]
export const optionCountry = [
    {value:"pakistan",label:"Pakistan"},
    {value:"UAE",label:"UAE"},
]
export const optionDrive = [
    {value:"2wd",label:"2wd"},
    {value:"4wd",label:"4wd"},
]
export const optionFuelType = [
    {value:"diesel",label:"diesel"},
    {value:"petrol",label:"petrol"},
    {value:"hybrid",label:"hybrid"},
    {value:"electric",label:"electric"},
]
export const optionRegistered = [
    {value:"registered",label:"registered"},
    {value:"un-registerd",label:"un-registerd"},
]

export const optionBody = [
    {value:"hashback",label:"hashback"},
    {value:"sedan",label:"sedan"},
    {value:"suv",label:"suv"},
    {value:"wagon",label:"wagon"},
    {value:"van",label:"van"},
    {value:"pickup",label:"pickup"},
];

export const optionTransmission = [
    {value:"automatic",label:"automatic"},
    {value:"manual",label:"manual"},
    {value:"semi-automatic",label:"semi-automatic"},
];
export const optionLocation = [
    {value:"Shop # 4, Memon Cottages, Block 2, Gulistan-e-Jauhar, Karachi.",label:"Shop # 4, Memon Cottages, Block 2, Gulistan-e-Jauhar, Karachi."},
    {value:"Shop # 2,3, Pakistan Homes Plot # 70-71, Block 9-A, Gulistan-e-Jauhar, Karachi.",label:"Shop # 2,3, Pakistan Homes Plot # 70-71, Block 9-A, Gulistan-e-Jauhar, Karachi."},
    {value:"Plot # 605-0, warehouse No. S 01, near Pak International Energy Fuel, Ras Al Khor Industrial Second, Dubai, UAE.",label:"Plot # 605-0, warehouse No. S 01, near Pak International Energy Fuel, Ras Al Khor Industrial Second, Dubai, UAE."},
];

export const optionMinYear = [
    {value:"2010",label:"2010"},
    {value:"2011",label:"2011"},
    {value:"2012",label:"2012"},
    {value:"2013",label:"2013"},
    {value:"2014",label:"2014"},
    {value:"2015",label:"2015"},
    {value:"2016",label:"2016"},
]
export const optionMaxYear = [
    {value:"2024",label:"2024"},
    {value:"2023",label:"2023"},
    {value:"2022",label:"2022"},
    {value:"2021",label:"2021"},
    {value:"2020",label:"2020"},
];
export const optionYear = [
    {value:"2024",label:"2024"},
    {value:"2023",label:"2023"},
    {value:"2022",label:"2022"},
    {value:"2021",label:"2021"},
    {value:"2020",label:"2020"},
    {value:"2019",label:"2019"},
    {value:"2018",label:"2018"},
    {value:"2017",label:"2017"},
    {value:"2016",label:"2016"},
    {value:"2015",label:"2015"},
    {value:"2014",label:"2014"},
    {value:"2013",label:"2013"},
    {value:"2012",label:"2012"},
    {value:"2011",label:"2011"},
    {value:"2010",label:"2010"},
    {value:"2009",label:"2009"},
    {value:"2008",label:"2008"},
    {value:"2007",label:"2007"},
    {value:"2006",label:"2006"},
    {value:"2005",label:"2005"},
    {value:"2004",label:"2004"},
    {value:"2003",label:"2003"},
    {value:"2002",label:"2002"},
    {value:"2001",label:"2001"},
    {value:"2000",label:"2000"},
    {value:"1999",label:"1999"},
    {value:"1998",label:"1998"},
    {value:"1997",label:"1997"},
    {value:"1996",label:"1996"},
    {value:"1995",label:"1995"},
    {value:"1994",label:"1994"},
    {value:"1993",label:"1993"},
    {value:"1992",label:"1992"},
    {value:"1991",label:"1991"},
    {value:"1990",label:"1990"},
];


export const featureData = [
    {
        title:"BMW 3 Series new 2024",
        price:22000000,
        mileage:"72000",
        transmission:"Manual",
        images:[
            car1,
            car2,
        ]
    },
    {
        title:"Raize 2017 imaculate condition",
        price:4200000,
        mileage:"72000",
        transmission:"Auto",
        images:[
            car2,
            car1,
        ]
    },
    {
        title:"Passo 2020 good condition",
        price:4200000,
        mileage:"72000",
        transmission:"Auto",
        images:[
            car3,
            car2,
        ]
    },
    {
        title:"Nissan Dayz 2021",
        price:3850000,
        mileage:"4000",
        transmission:"Auto",
        images:[
            car4,
            car2,
        ]
    },
    {
        title:"BMW 3 Series new 2024",
        price:22000000,
        mileage:"72000",
        transmission:"Manual",
        images:[
            car1,
            car2,
        ]
    },
    {
        title:"Raize 2017 imaculate condition",
        price:4200000,
        mileage:"72000",
        transmission:"Auto",
        images:[
            car2,
            car1,
        ]
    },
    {
        title:"Passo 2020 good condition",
        price:4200000,
        mileage:"72000",
        transmission:"Auto",
        images:[
            car3,
            car2,
        ]
    },
    {
        title:"Nissan Dayz 2021",
        price:3850000,
        mileage:"4000",
        transmission:"Auto",
        images:[
            car4,
            car2,
        ]
    },
]

import React from 'react'
import { Header } from '../header/header'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import banner from "../../assets/images/bannerImg.jpg"
import banner1 from "../../assets/images/banner1.jpg"
import banner2 from "../../assets/images/banner2.jpg"
import banner3 from "../../assets/images/banner3.jpg"

export const HeaderWithBanner = () => {
  return (
    <>
    <Header />
        <section className='BannerSec' id="BannerSec">
              <Swiper navigation={true} modules={[Autoplay, Navigation]} className="mySwiper w-100" 
              loop={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }} 
                pagination={{
                  clickable: true,
                }}
                >
                <SwiperSlide><img src={banner1} alt="" /></SwiperSlide>
                <SwiperSlide><img src={banner2} alt="" /></SwiperSlide>
                <SwiperSlide><img src={banner3} alt="" /></SwiperSlide>
              </Swiper>
            <div className="container pr">
                {/* <div className="numOfVehicle">
                    <div className="num">100+</div>
                    <div className="title">Vehicle Available</div>
                </div> */}
            </div>
        </section>
    </>
  )
}

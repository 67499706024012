import React, { useEffect, useState } from 'react'
import { HeaderWithBanner } from '../component/headerWithBanner.js'
import { FilterComp } from '../component/filter/filter.js'
import { animate } from '../assets/js/animate.js'
import api from '../api/index.js'

export const ContactPageUAE = () => {
    const [loader,setLoader]= useState(false);
    const [data,setData]= useState([]);
    useEffect(() => {
        animate()
    }, [])
    const getTeam = async()=>{
        try {
        
          setLoader(true)
          const postData = await api.getTeam()
        //   console.log(postData);
          setData(postData.team || [])
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }
      useEffect(() => {
        getTeam()
      }, [])

  return (
    <div className='contactPage'>
        <HeaderWithBanner />
        <FilterComp />

        <div className="addressMain">
            <div className="container">
                <div className="homeHeading">Pak <span>Auto</span> Source Office (UAE)</div>
                <div className="row">
                <div className="col-lg-4 mb-2">
                    <div className="addressCard">
                        <div className="addressHead">Contact Detail</div>
                       <div className="addressCardInner">
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-location-dot"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Address</div>
                                    <div className="text">Plot # 605-0, warehouse No. S 01, near Pak International Energy Fuel, Ras Al Khor Industrial Second, Dubai, UAE.</div>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-phone-volume"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Sales Phone</div>
                                    <a className="text" href="tel:+971547682024">+971 547682024</a>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-envelope"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Email</div>
                                    <a className="text" href="mailto:info@pakautosource.co">info@pakautosource.co</a>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="mapMain">
                        <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2227.3361539717275!2d55.35308683139605!3d25.17877158305184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f665550ed8eb9%3A0xc1329059db67ab92!2sRas%20Al%20Khor%20Industrial%20Area%20-%20Ras%20Al%20Khor%20Industrial%20Area%202%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725059182729!5m2!1sen!2s" width="600" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <section className='ourTeam'>
            <div className="container">
                <div className="homeHeading">Our <span>Team</span> In (Branch 3)</div>
                <div className="row">
                    {data.map((item,key)=>(
                    item?.branch == 3 &&
                    <div className={`col-md-4 mb-3 animatable slideInUp animationDelay${key+1}`} key={key}>
                        <TeamCard name={item?.name} desig={item?.desig} contact={item?.contact} />
                    </div>
                    ))}
                </div>
            </div>
        </section>

    </div>
  )
}

const TeamCard = ({name,desig,contact}) => {
    return(
        <div className="teamCard">
            <div className="icon"><i class="fa-solid fa-id-card-clip"></i></div>
            <div className="content">
                <div className="name">{name}</div>
                <div className="desig">{desig}</div>
                <a href={`tel:${contact}`} className="contact">{contact}</a>
            </div>
        </div>
    )
}

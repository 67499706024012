import React from 'react'
import Logo from "../../assets/images/logo.svg" 
import loader from "../../assets/images/loader.gif" 

export const LoaderComp = ({}) => {
  return (
    <>
          <div className="seekoLoader">
              <div className="loader">
                  {/* <img src={Logo} alt="loader" className='logo' /> */}
                  <img src={loader} alt="loader" className='logo' />

              </div>
          </div>
    </>
  )
}

export const SET_ADMIN = "SET_ADMIN";

const action = {};

action.setAdmin = (payload) => {
  return {
    type: SET_ADMIN,
    payload,
  };
};

export default action;

import React, { useEffect, useState } from 'react'
import { HeaderWithBanner } from '../component/headerWithBanner.js'
import { FilterComp } from '../component/filter/filter.js'
import { animate } from '../assets/js/animate.js'
import api from '../api/index.js'

export const ContactPage = () => {
    const [loader,setLoader]= useState(false);
    const [data,setData]= useState([]);
    useEffect(() => {
        animate()
    }, [])
    const getTeam = async()=>{
        try {
        
          setLoader(true)
          const postData = await api.getTeam()
        //   console.log(postData);
          setData(postData.team || [])
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }
      useEffect(() => {
        getTeam()
      }, [])

  return (
    <div className='contactPage'>
        <HeaderWithBanner />
        <FilterComp />

        <div className="addressMain">
            <div className="container">
                <div className="homeHeading">Pak <span>Auto</span> Source Office (Branch 1)</div>
                <div className="row">
                <div className="col-lg-4 mb-2">
                    <div className="addressCard">
                        <div className="addressHead">Contact Detail</div>
                       <div className="addressCardInner">
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-location-dot"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Address</div>
                                    <div className="text">Shop # 4, Memon Cottages, Block 2, Gulistan-e-Jauhar, Karachi.</div>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-phone-volume"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Sales Phone</div>
                                    <a className="text" href="tel:+92 336 3929249">+92 336 3929249</a>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-envelope"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Email</div>
                                    <a className="text" href="mailto:info@pakautosource.co">info@pakautosource.co</a>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="mapMain">
                        <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.928625694343!2d67.13086677475545!3d24.93450127788098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f0898f0255d%3A0x9b76d112c9b2b74d!2sPAK%20AUTO%20SOURCE!5e0!3m2!1sen!2s!4v1721165914501!5m2!1sen!2s" width="600" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <section className='ourTeam'>
            <div className="container">
                <div className="homeHeading">Our <span>Team</span> In (Branch 1)</div>
                <div className="row">
                    {data.map((item,key)=>(
                    item?.branch == 1 &&
                    <div className={`col-md-4 mb-3 animatable slideInUp animationDelay${key+1}`} key={key}>
                        <TeamCard name={item?.name} desig={item?.desig} contact={item?.contact} />
                    </div>
                    ))}
                    {/* <div className="col-md-4 animatable slideInUp animationDelay100">
                        <TeamCard name={"Mohsin Khan"} desig={"Sales Coordinator"} contact={"+92 314 2575320"} />
                    </div>
                    <div className="col-md-4 animatable slideInUp animationDelay200">
                        <TeamCard name={"Zoraiz Khan"} desig={"Sales Coordinator"} contact={"+92 336 3929249"} />
                    </div> */}
                </div>
            </div>
        </section>

        <div className="addressMain">
            <div className="container">
                <div className="homeHeading">Pak <span>Auto</span> Source Office (Branch 2)</div>
                <div className="row">
                <div className="col-lg-4 mb-2">
                    <div className="addressCard">
                        <div className="addressHead">Contact Detail</div>
                       <div className="addressCardInner">
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-location-dot"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Address</div>
                                    <div className="text">Shop # 2,3, Pakistan Homes Plot # 70-71, Block 9-A, Gulistan-e-Jauhar, Karachi.</div>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-phone-volume"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Sales Phone</div>
                                    <a className="text" href="tel:+92 336 3929249">+92 336 3929249</a>
                                </div>
                            </div>
                            <div className="addressRow">
                                <div className="icon"><i className="fa-solid fa-envelope"></i></div>
                                <div className="content animatable slideInUp">
                                    <div className="title">Email</div>
                                    <a className="text" href="mailto:info@pakautosource.co">info@pakautosource.co</a>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="mapMain">
                        <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.1515519262225!2d67.1588252747551!3d24.92690657788598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb339d05ed79a07%3A0xe2e43a7f0d3644bb!2sPAK%20AUTO%20SOURCE%20(Branch%202)!5e0!3m2!1sen!2s!4v1721178745640!5m2!1sen!2s" width="600" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <section className='ourTeam'>
            <div className="container">
                <div className="homeHeading">Our <span>Team</span> In (Branch 2)</div>
                <div className="row">
                    {data.map((item,key)=>(
                    item?.branch == 2 &&
                    <div className={`col-md-4 mb-3 animatable slideInUp animationDelay${key+1}`} key={key}>
                        <TeamCard name={item?.name} desig={item?.desig} contact={item?.contact} />
                    </div>
                    ))}
                </div>
            </div>
        </section>
    </div>
  )
}

const TeamCard = ({name,desig,contact}) => {
    return(
        <div className="teamCard">
            <div className="icon"><i class="fa-solid fa-id-card-clip"></i></div>
            <div className="content">
                <div className="name">{name}</div>
                <div className="desig">{desig}</div>
                <a href={`tel:${contact}`} className="contact">{contact}</a>
            </div>
        </div>
    )
}

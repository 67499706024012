import React from 'react'
import review1 from "../../assets/images/review1.jpg";
import review2 from "../../assets/images/civic.jpg";
import review3 from "../../assets/images/yaris.jfif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Review = ({reviewData}) => {
    // const reviewData = [
    //     {
    //         name:"Ahsan Iqbal",
    //         description:"Very nice service! I am very happy.",
    //         image:review1,
    //         reviewOn:"toyota raize"
    //     },
    //     {
    //         name:"Muhammad Ali",
    //         description:"Very nice service! I am very happy.",
    //         image:review2,
    //         reviewOn:"Honda civic"
    //     },
    //     {
    //         name:"Mohsin",
    //         description:"Very nice service! I am very happy.",
    //         image:review3,
    //         reviewOn:"toyota yaris"
    //     },
    // ]

    const settings1 = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
         
        ]
      };

  return (
    <section className='reviewSec'>
            <div className="container">
                <h3 className='homeHeading'>Reviews by <span>Customer</span></h3>
                
                <Slider {...settings1} >
                    {reviewData.map((item,key)=>(

                    <div className="p-2" key={key}>
                        <div className="reviewCard">
                            <div className="imgDiv">
                                <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.logo}`} alt="" />
                            </div>
                            <div className="content">
                                <div className="name">{item?.title}</div>
                                <div className="desc">{item?.description}</div>
                                <div className="stars my-1">
                                  {[...Array(parseInt(item?.rating))].map((val,key)=>(
                                    <i class="fa-solid fa-star"></i>
                                  ))}

                               
                                </div>
                                <div className="revOn">Review on toyota yaris</div>
                            </div>
                        </div>
                    </div>
                    ))}
                </Slider>
                 
            </div>
        </section>
  )
}

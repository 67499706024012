import React from 'react'
import logo from "../../assets/images/logo.svg";
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer>
        <div className="container">
            <div className="row1">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <div className="linkM">
                    <Link to="/" className="link">Home</Link>
                    <Link to="/about" className="link">About Us</Link>
                    <Link to="/contact" className="link">Contact Us</Link>
                </div>
            </div>
            <div className="row2">
                <div className="text">Copyright © 2024. PakAutoSource, All rights reserved</div>
                <div className="socialMain">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i class="fa-brands fa-x-twitter"></i>                        
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-instagram"></i>            
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-linkedin-in"></i>            
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-youtube"></i>            
                    </a>
                </div>       
            </div>
        </div>
    </footer>
  )
}

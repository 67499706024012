import React, { useEffect, useState } from 'react'
import { Header } from '../component/header/header'
import { FilterComp } from '../component/filter/filter';
import toyota from "../assets/images/toyota.png"
import honda from "../assets/images/honda.png"
import kia from "../assets/images/kia.png"
import bentley from "../assets/images/bentley.png"
import bmw from "../assets/images/bmw.png"
import hyundai from "../assets/images/hyundai.png"
import chevy from "../assets/images/chevy.png"
import ford from "../assets/images/ford.png"

import allCars from "../assets/images/allCars.png";
import imported from "../assets/images/imported.png";
import localCars from "../assets/images/localCars.png";
import branch from "../assets/images/branch.png";
import gearshift from "../assets/images/gearshift.png";
import car1 from "../assets/images/car1.jpg";
import car2 from "../assets/images/car2.jpg";
import car3 from "../assets/images/car3.jpg";
import car4 from "../assets/images/car4.jpg";

import { Footer } from '../component/footer/footer';
import { Review } from '../component/reviewSection/review';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderWithBanner } from '../component/headerWithBanner.js';
import { animate } from '../assets/js/animate.js';
import CountUp from 'react-countup';
import api from '../api/index.js';
import { LoaderComp } from '../component/loader/loader.js';

export const Home = () => {
    const navigate=useNavigate()
    const [data, setData] = useState([]);
    const [stats, setStats] = useState([]);
    const [loader, setLoader] = useState(true);
    const [maker, setMaker] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("pakistan");

  



    function numDifferentiation(value) {
        const val = Math.abs(value)
        if (val >= 10000000) return `${Number.parseFloat((value / 10000000).toFixed(2))} Cr`
        if (val >= 100000) return `${Number.parseFloat((value / 100000).toFixed(2))} Lac`
        return value;
      }

      const getFVehicle = async()=>{
        try {
            let payload = {
                country:selectedCountry
            }
          setLoader(true)
          const postData = await api.getFeaturedVehicle(payload)
        //   console.log(postData);
          setData(postData.vehicle)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }
      const getStats = async()=>{
        try {
       
          setLoader(true)
          const postData = await api.getHomeStats()
        //   console.log(postData);
          setStats(postData)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const getMakers = async()=>{
        try {
   
          setLoader(true)
          const postData = await api.getAllMake()
        //   console.log(postData);
          setMaker(postData.make)
          setLoader(false)
          animate()
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const getReviews = async()=>{
        try {
    
          setLoader(true)
          const postData = await api.getReview()
        //   console.log(postData);
          setReviews(postData.review)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      useEffect(() => {
        getStats()
        getMakers()
        getReviews()
    }, [])
      useEffect(() => {
        getFVehicle()
    }, [selectedCountry])
   
  return (
    <div className="homePage">
        <HeaderWithBanner />
        <FilterComp />

        <section className='brawseByMakeSec'>
            <div className="container">
                <h3 className='homeHeading'>Browse by <span>Make</span></h3>
                <div className="makeRow">
                    {maker.slice(0,5).map((item,key)=>(
                        <Link to={`/stock/pakistan/?make=${item.name}`} className={`nameCol animatable slideInUp animationDelay${key}00`} key={key}>
                            <div className="imgDiv"><img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.logo}`} alt="" /></div>
                            <div className="title">{item.name}</div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>

        <section className='featuredSec'>
            <div className="container">
                <h3 className='homeHeading'>Featured Cars</h3>
                <div className="countryBtnRow">
                    <button onClick={()=>setSelectedCountry("pakistan")} className={selectedCountry == "pakistan" ?"active":""}>Pakistan</button>
                    <button onClick={()=>setSelectedCountry("UAE")} className={selectedCountry == "UAE" ?"active":""}>UAE</button>
                </div>
                <div className="row">
                    {data.map((item,key)=>(
                        <div className="col-lg-3 col-sm-6 mb-3" key={key}>
                            <div className="productCard" onClick={()=>navigate(`/stock/${item?.stockId}`)}>
                                <div className="imgDiv">
                                    <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.images[0]}`} alt="" />
                                </div>
                                <div className="titleRow">
                                    <div className="title">{item?.title}</div>
                                    {item?.price == 0 ?
                                    <div className="price">Call for Price</div>
                                    :
                                    <>
                                    {item?.country == "UAE" ?
                                    <div className="price">{item?.price} AED</div>
                                    :
                                    <div className="price">Rs {numDifferentiation(item?.price)}</div>
                                    }
                                    </>
                                    }
                                </div>
                                <div className="statsRow">
                                    <div className="val">
                                        {item?.transmission}
                                        <img className='ml-1' src={gearshift} alt="" />
                                    </div>
                                    <div className="val">
                                        {item?.mileage} mi
                                        <i className="fa-solid fa-road ml-1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='text-center mt-5'>
                    {selectedCountry == "UAE" ?
                    <button className="themeBtn" onClick={()=>navigate("/stock/uae")}>Show All</button>
                    :
                    <button className="themeBtn" onClick={()=>navigate("/stock/pakistan")}>Show All</button>
                    }
                </div>
            </div>
        </section>

        <section className='statsSec'>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <div className="stats">
                            <div className="ico"><img src={allCars} alt="" /></div>
                            <div>
                                
                                <h3>
                                    <CountUp
                                    start={0}
                                    end={stats?.forSale ? stats?.forSale : 0}
                                    duration={4}  
                                    enableScrollSpy={true}
                                    /> 
                                </h3>
                                <div className="title">CARS FOR SALE</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="stats">
                            <div className="ico"><img src={imported} alt="" /></div>
                            <div>
                                <h3>  
                                    <CountUp
                                    start={0}
                                    end={stats?.local ? stats?.local : 0}
                                    duration={4}  
                                    enableScrollSpy={true}
                                    />
                                </h3>
                                <div className="title">LOCAL CARS</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="stats">
                            <div className="ico"><img src={localCars} alt="" /></div>
                            <div>
                                <h3>
                                    <CountUp
                                    start={0}
                                    end={stats?.imported ? stats?.imported : 0}
                                    duration={4}  
                                    enableScrollSpy={true}
                                    />
                                </h3>
                                <div className="title">IMPORTED CARS</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="stats">
                            <div className="ico"><img src={branch} alt="" /></div>
                            <div>
                                <h3>
                                    <CountUp
                                    start={0}
                                    end={2}
                                    duration={4}  
                                    enableScrollSpy={true}
                                    />
                                </h3>
                                <div className="title">BRANCHES</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Review reviewData={reviews} />
        {loader &&
        <LoaderComp />
        }
        
    </div>
  )
}

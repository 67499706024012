import React,{useEffect,useRef,useState} from 'react';
import upload from "../../assets/images/uploadIco.svg";
import logo from "../../assets/images/logo.png";
import { useAlert } from 'react-alert';
import { LoaderComp } from '../../component/loader/loader';
import { useReactToPrint } from 'react-to-print';
import './reciept.css'
import { optionLocation } from '../../utils/optionData';

function Reciept({}) {
  const alert = useAlert()
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [payMethod,setPayMethod]= useState('Cash')
  const [payIn,setPayIn]= useState('Full Payment')
    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col pb-3">
                        <RecieptComp ref={componentRef} payMethod={payMethod} setPayMethod={setPayMethod} setPayIn={setPayIn} payIn={payIn} />
                        <div className="text-center mt-2" >
                            <button className="themeBtn"  onClick={handlePrint}>Generate Print</button>
                        </div>
                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
         
      </>
    )
  }

//   const RecieptComp = () => {
//     return(
//         <div className="recieptMain">
//                             <div className="logo"><img src={logo} alt="pak auto source" /></div>
//                             <h2 className="heading">Car Sale Reciept</h2>
//                             <div className="row mb-2">
//                                 <div className="col-md-6">
//                                     <div className="tableM">
//                                         <div className="tabHead">Seller Information</div>
//                                         <div className="tableContent">
//                                             <div className="infoRow">
//                                                 <div className="title">Name:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Full Name' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Address:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Address' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Phone Number:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Phone Number' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Email Address:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Email Address' />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="tableM">
//                                         <div className="tabHead">Buyer Information</div>
//                                         <div className="tableContent">
//                                             <div className="infoRow">
//                                                 <div className="title">Name:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Full Name' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Address:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Address' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Phone Number:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Phone Number' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Email Address:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Email Address' />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <h3 className="titleHead">Vehicle Information</h3>
//                             <div className="row mb-2">
//                                 <div className="col-4 mb-2">
//                                     <div className="pageName">Vehicle Make</div>
//                                     <div className="pageVal">
//                                         <input type="text" placeholder='Vehicle Make' />
//                                     </div>
//                                 </div>
//                                 <div className="col-4 mb-2 text-center">
//                                     <div className="pageName">Vehicle Model</div>
//                                     <div className="pageVal">
//                                         <input className='text-center' type="text" placeholder='Vehicle Make' />
//                                     </div>
//                                 </div>
//                                 <div className="col-4 mb-2 text-right">
//                                     <div className="pageName">Vehicle Year</div>
//                                     <div className="pageVal">
//                                         <input className='text-right' type="text" placeholder='Vehicle Make' />
//                                     </div>
//                                 </div>
//                                 <div className="col-4 mb-2">
//                                     <div className="pageName">Chasis/Registration No.</div>
//                                     <div className="pageVal">
//                                         <input type="text" placeholder='Vehicle Make' />
//                                     </div>
//                                 </div>
//                                 <div className="col-4 mb-2 text-center">
//                                     <div className="pageName">Mileage</div>
//                                     <div className="pageVal">
//                                         <input className='text-center' type="number" placeholder='Mileage' />
//                                     </div>
//                                 </div>
//                                 <div className="col-4 mb-2 text-right">
//                                     <div className="pageName">Color</div>
//                                     <div className="pageVal">
//                                         <input className='text-right' type="text" placeholder='Color' />
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row mb-2">
//                                 <div className="col-md-6">
//                                     <div className="tableM">
//                                         <div className="tabHead">Sale Information</div>
//                                         <div className="tableContent">
//                                             <div className="infoRow">
//                                                 <div className="title">Sale Date:</div>
//                                                 <div className="val">
//                                                     <input type="date" placeholder='Date' />
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Sale Price:</div>
//                                                 <div className="val">
//                                                     <input type="text" placeholder='Full Name' />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="tableM">
//                                         <div className="tabHead">Payment Information</div>
//                                         <div className="tableContent">
//                                             <div className="infoRow">
//                                                 <div className="title">Payment Method:</div>
//                                                 <div className="pMethod">
//                                                     <span>Cash</span>/
//                                                     <span>Check</span>/
//                                                     <span>Bank Transfer</span>/
//                                                     <span>Other</span>
//                                                 </div>
//                                             </div>
//                                             <div className="infoRow">
//                                                 <div className="title">Payment Term:</div>
//                                                 <div className="pMethod">
//                                                     <span>Full Payment</span>/ 
//                                                     <span>Installments</span> 
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='mb-3'>
//                                 <h3 className="titleHead">Additional Terms and Conditions:</h3>
//                                 <div className="textAreaM">
//                                     <textarea className='w-100' name="" id="" rows={1} placeholder='Any adddtional terms and condition agreed upon by both parties.'></textarea>
//                                 </div>
//                             </div>
//                             <div className='mb-3'>
//                                 <h3 className="titleHead">Warranty Information:</h3>
//                                 <div className="textAreaM">
//                                     <textarea className='w-100' name="" id="" rows={1} placeholder='Warranty Details, if applicable'></textarea>
//                                 </div>
//                             </div>
//                             <div className='mb-3'>
//                                 <h3 className="titleHead">Acknowlegment of Sale:</h3>
//                                 <div className="pageVal">
//                                     The undersigned seller acknowleges reciept of the above-mentioned amount and hearby transfers all ownership of the vehicle described above to the buyer. 
//                                 </div>
//                             </div>

//                             <div className="footer">
//                                 <div className="col1">
//                                     <div className="pageName mb-3">Seller's Signature <span className='underline'></span></div>
//                                     <div className="pageName">Buyer's Signature <span className='underline'></span></div>
//                                 </div>
//                                 <div className="col2">
//                                     <div className="pageName d-flex">Date: <input className='ml-2' type="date" name="" id="" /></div>
//                                 </div>
//                             </div>
//                         </div>
//     )
//   }

  export const RecieptComp = React.forwardRef((props, ref) => {
    const [seller,setSeller]= useState({
        name:"Pak Auto Source",
        address:"Shop # 4, Memon Cottages, Block 2, Gulistan-e-Jauhar, Karachi.",
        phone:"+92-336-3929249",

    });
    return (
      <div className="recieptMain" ref={ref}>
        <div className="logo text-center"><img src={logo} alt="pak auto source" /></div>
        <h2 className="heading">Car Sale Reciept</h2>

        <h3 className="titleHead">Vehicle Information</h3>
        <div className="row mb-3">
            <div className="col-4 mb-2">
                <div className="pageName">Vehicle Make</div>
                <div className="pageVal">
                    <input type="text" placeholder='Vehicle Make' />
                </div>
            </div>
            <div className="col-4 mb-2 text-center">
                <div className="pageName">Vehicle Model</div>
                <div className="pageVal">
                    <input className='text-center' type="text" placeholder='Vehicle Make' />
                </div>
            </div>
            <div className="col-4 mb-2 text-right">
                <div className="pageName">Vehicle Year</div>
                <div className="pageVal">
                    <input className='text-right' type="text" placeholder='Vehicle Make' />
                </div>
            </div>
            <div className="col-4 mb-2">
                <div className="pageName">Registration No.</div>
                <div className="pageVal">
                    <input type="text" placeholder='Vehicle Make' />
                </div>
            </div>
            <div className="col-4 mb-2 text-center">
                <div className="pageName">Chasis No.</div>
                <div className="pageVal">
                    <input className='text-center' type="text" placeholder='Vehicle Make' />
                </div>
            </div>
            <div className="col-4 mb-2 text-right">
                <div className="pageName">Mileage</div>
                <div className="pageVal">
                    <input className='text-right' type="text" placeholder='Mileage' />
                </div>
            </div>
            <div className="col-4 mb-2 ">
                <div className="pageName">Owner Name</div>
                <div className="pageVal">
                    <input className='' type="text" placeholder='Owner Name' />
                </div>
            </div>
            <div className="col-4 mb-2 text-center">
                <div className="pageName">Owner NIC</div>
                <div className="pageVal">
                    <input className='text-center' type="text" placeholder='Owner NIC' />
                </div>
            </div>
            <div className="col-4 mb-2 text-right">
                <div className="pageName">Color</div>
                <div className="pageVal">
                    <input className='text-right' type="text" placeholder='Color' />
                </div>
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6">
                <div className="tableM">
                    <div className="tabHead">Seller Information</div>
                    <div className="tableContent">
                        {/* <div className="infoRow">
                            <div className="title">NIC:</div>
                            <div className="val">
                                <input type="text" placeholder='NIC Number' />
                            </div>
                        </div> */}
                        <div className="infoRow">
                            <div className="title">Name:</div>
                            <div className="val">
                                <input type="text" onChange={(e)=>setSeller({...seller,["name"]:e.target.value})} placeholder='Full Name' value={seller?.name} />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Address:</div>
                            <div className="val">
                                {/* <input type="text" placeholder='Address' /> */}
                                {/* <select value={seller.address} name="name">
                                    {optionLocation.map((val,key)=>(
                                        <option value={val?.value} key={key}>{val?.value}</option>
                                    ))}
                                </select> */}
                                <div class="dropdown">
                                <button class="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {seller.address}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {optionLocation.map((val,key)=>(
                                        <a class="dropdown-item" key={key} onClick={()=>setSeller({...seller,["address"]:val?.value})}>{val?.value}</a>
                                        ))}
                  
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Phone Number:</div>
                            <div className="val">
                                <input type="text" placeholder='Phone Number' onChange={(e)=>setSeller({...seller,["phone"]:e.target.value})} value={seller.phone} />
                            </div>
                        </div>
                        {/* <div className="infoRow">
                            <div className="title">Email Address:</div>
                            <div className="val">
                                <input type="text" placeholder='Email Address' />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="tableM">
                    <div className="tabHead">Buyer Information</div>
                    <div className="tableContent">
                        <div className="infoRow">
                            <div className="title">NIC:</div>
                            <div className="val">
                                <input type="text" placeholder='NIC Number' />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Name:</div>
                            <div className="val">
                                <input type="text" placeholder='Full Name' />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Address:</div>
                            <div className="val">
                                <input type="text" placeholder='Address' />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Phone Number:</div>
                            <div className="val">
                                <input type="text" placeholder='Phone Number' />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Email Address:</div>
                            <div className="val">
                                <input type="text" placeholder='Email Address' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6">
                <div className="tableM">
                    <div className="tabHead">Sale Information</div>
                    <div className="tableContent">
                        <div className="infoRow">
                            <div className="title">Sale Date:</div>
                            <div className="val">
                                <input type="date" placeholder='Date' />
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Sale Price:</div>
                            <div className="val">
                                <input type="text" placeholder='Full Name' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="tableM">
                    <div className="tabHead">Payment Information</div>
                    <div className="tableContent">
                        <div className="infoRow">
                            <div className="title">Payment Method:</div>
                            <div className="pMethod">
                                <span onClick={()=>props.setPayMethod("Cash")}>{props.payMethod == "Cash" && <i className="fa-solid fa-check"></i>} Cash</span>/
                                <span onClick={()=>props.setPayMethod("Check")}>{props.payMethod == "Check" && <i className="fa-solid fa-check"></i>} Check</span>/
                                <span onClick={()=>props.setPayMethod("Bank Transfer")}>{props.payMethod == "Bank Transfer" && <i className="fa-solid fa-check"></i>} Bank Transfer</span>/
                                <span onClick={()=>props.setPayMethod("Other")}>{props.payMethod == "Other" && <i className="fa-solid fa-check"></i>} Other</span>
                            </div>
                        </div>
                        <div className="infoRow">
                            <div className="title">Payment Term:</div>
                            <div className="pMethod">
                                <span onClick={()=>props.setPayIn("Full Payment")}>{props.payIn =="Full Payment" && <i className="fa-solid fa-check"></i>} Full Payment</span>/ 
                                <span onClick={()=>props.setPayIn("Installments")}>{props.payIn =="Installments" && <i className="fa-solid fa-check"></i>} Installments</span> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mb-3'>
            <h3 className="titleHead">Additional Terms and Conditions:</h3>
            <div className="textAreaM">
                <textarea className='w-100' name="" id="" rows={1} placeholder='Any adddtional terms and condition agreed upon by both parties.'></textarea>
            </div>
        </div>
        <div className='mb-3'>
            <h3 className="titleHead">Warranty Information:</h3>
            <div className="textAreaM">
                <textarea className='w-100' name="" id="" rows={1} placeholder='Warranty Details, if applicable'></textarea>
            </div>
        </div>
        <div className='mb-3'>
            <h3 className="titleHead">Acknowlegment of Sale:</h3>
            <div className="pageVal">
                The undersigned seller acknowleges reciept of the above-mentioned amount and hearby transfers all ownership of the vehicle described above to the buyer. 
            </div>
        </div>

        <div className="footer">
            <div className="col1">
                <div className="pageName mb-3">Seller's Signature <span className='underline'></span></div>
                <div className="pageName">Buyer's Signature <span className='underline'></span></div>
            </div>
            <div className="col2">
                <div className="pageName d-flex">Date: <input className='ml-2' type="date" name="" id="" /></div>
            </div>
        </div>

        <div className="footerMini">
            <div>
                <div className="address mb-1"><i className="fa-solid fa-location-dot mr-1"></i> {optionLocation[0].value}</div>
                <div className="address"><i className="fa-solid fa-location-dot mr-1"></i> {optionLocation[1].value}</div>
            </div>
            <a className='site' href="https://pakautosource.co/" target="_blank" rel="noopener noreferrer">www.pakautosource.co</a>
        </div>
    </div>
    );
  });

  export default Reciept
import React, { useEffect } from 'react'
import { Header } from '../component/header/header'
import yaris from "../assets/images/yaris.png"
import logo from "../assets/images/logo.png"
import global from "../assets/images/global.jpg"
import local from "../assets/images/local.jpg"
import mission from "../assets/images/mission.jpg"
import { animate } from '../assets/js/animate'

export const AboutPage = () => {

    useEffect(() => {
        animate()
    }, [])

  return (
    <div className='aboutPage'>
        <Header />

        <div className="bannerSecAbout">
            {/* <h2 className="heading">About Pak Auto Source</h2> */}
        </div>

        <section className='aboutSec'>
            <div className="container">
                <div className="aboutRow">
                    <div className="imgDivBorder animatable bounceInLeft animationDelay100">
                        <div className="imgDiv">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="line animationDelay100"></div>
                    <div className="circle "></div>
                    <div className="content">
                        <h3>Our Business</h3>
                        <p>we specialize in both Japanese and local vehicles, offering a diverse selection to meet every driver's needs. Our expert team ensures top-notch service and quality, whether you're looking for the latest Japanese models or reliable local favorites. Discover exceptional value and personalized care with us!</p>
                    </div>
                </div>
                <div className="aboutRow right">
                    <div className="content">
                        <h3>Global connectivity</h3>
                        <p>We operate in multiple countries, providing our global clientele with unmatched access to a diverse range of used Japanese vehicles.</p>
                    </div>
                    <div className="circle" ></div>
                    <div className="line" ></div>
                    <div className="imgDivBorder animatable bounceInRight ">
                        <div className="imgDiv">
                            <img src={global} alt="" />
                        </div>
                    </div>
                </div>
                <div className="aboutRow">
                    <div className="imgDivBorder animatable bounceInLeft">
                        <div className="imgDiv">
                            <img src={local} alt="" />
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="circle"></div>
                    <div className="content">
                        <h3>Home network</h3>
                        <p>Our base in Pakistan run deep, with multiple strategically located yards across the nation. These yards serve as central hubs for our meticulously inspected, high-quality inventory.</p>
                    </div>
                </div>
                <div className="aboutRow right">
                    <div className="content">
                        <h3>Our Mission</h3>
                        <p>We operate in multiple countries, providing our global clientele with unmatched access to a diverse range of used Japanese vehicles.</p>
                    </div>
                    <div className="circle"></div>
                    <div className="line"></div>
                    <div className="imgDivBorder animatable bounceInRight">
                        <div className="imgDiv">
                            <img src={mission} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container py-5">
            <section className='companyInfoSec'>
                <div className="heading">Company Information</div>
                <div className="compRow">
                    <div className="compCard animatable slideInUp">
                        <div className="name">Company Name</div>
                        <div className="val">Pak Auto Source</div>
                    </div>
                    <div className="compCard animatable slideInUp animationDelay100">
                        <div className="name">Established</div>
                        <div className="val">Jan, 2019</div>
                    </div>
                    <div className="compCard animatable slideInUp animationDelay200">
                        <div className="name">CEO</div>
                        <div className="val">M. Zain Khan</div>
                    </div>
                    <div className="compCard animatable slideInUp animationDelay300">
                        <div className="name">Head Office Location</div>
                        <div className="val">Gulistan e Johor, Block 15, Karachi</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import plus from "../../assets/images/plus.svg";
import upload from "../../assets/images/uploadIco.svg";
import { driverData, MakeData, MakeDataImg } from "./makeData";
import api from '../../api';
import { useAlert } from 'react-alert';
import { Pagination, Stack } from '@mui/material';
import Select from 'react-select'
import { LoaderComp } from '../../component/loader/loader';


function Maker({}) {
  const alert = useAlert()
  const [selectedFile, setSelectedFile] = useState(null);
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [loader, setLoader] = useState(false);
  const [formName, setFormName] = useState("");
  const [formLogo, setFormLogo] = useState(null);
  const [noOfMake, setnoOfMake]=useState(0)
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [isManual, setIsManual] = useState(false);
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (val) => {
    setShowEdit(true)
    setEditData(val)
  };


  const getMakers = async()=>{
    try {
      let payload = {
        page:page
      }
      setLoader(true)
      const postData = await api.getMake(payload)
      // console.log(postData);
      setData(postData.make)
      setLimit(postData.limit)
      setnoOfMake(postData.count)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleChangeLogo = (e) => {
    if(e.target.files[0].size > 300000){
      alert.show("You can upload max 200kb file")
    }
    else{
      setFormLogo(e.target.files[0])
    }
  }

  const handleChangeLogoEdit = (e) => {
    if(e.target.files[0].size > 300000){
      alert.show("You can upload max 200kb file")
    }
    else{
      setEditData({...editData,"logo":e.target.files[0]})
    }
  }

  const checkValidation = () => {
    if(!formName) {
      return "Please insert company name"
    }
    if(!formLogo) {
      return "Please add company logo"
    }
    return false
  }

  const handleSubmit = async() => {
    try {      
      let checks = checkValidation()
      if(checks){
        return alert.show(checks)
      }
      setLoader(true)
      
      const formData = new FormData();
      formData.append("name", formName);
      formData.append("image", formLogo, formLogo.name);
  
      const response = await api.addMake(formData);
      handleClose();
      page == 1 ? getMakers():setPage(1);
      alert.show("Make successfully uploaded.")
      setFormLogo(null)
      setFormName("")
      setLoader(false)
      
    } catch (error) {
      alert.show("Something went wrong.")
      setLoader(false)
    }
  }  

  const deletePost = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.makeDelete(payload)
      console.log("deleted");
      // console.log(userData);
      alert.show("Maker deleted.")
      getMakers()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleEdit = async() => {
    try {      
      const formData = new FormData();
      formData.append("_id", editData?._id);
      formData.append("name", editData?.name);
      {(typeof editData?.logo) != "string" &&
        formData.append("image", editData?.logo, editData?.logo?.name);
      }
  
      const response = await api.editMake(formData);
      // console.log(response);
      
      if(response?.success){
        handleClose();
        page == 1 ? getMakers():setPage(1);
        alert.show("Make Edited successfully.")
        handleCloseEdit()
      }else{
        alert.show("Update Failed.")
      }
    } catch (error) {
      alert.show("Something went wrong.")
    }
  }

  async function getFileFromUrl(url, name, defaultType = 'image/png') {
    const response = await fetch(url)
    const data = await response.blob()
    let res1= new File([data], name, {
      type: data.type || defaultType,
    })
    // console.log(res1);
    setFormLogo(res1)
    // return
    
  }

  useEffect(() => {
    getMakers()
  }, [page])

  const formatOptionLabel = ({ value, label }, { context }) => {
    const vehicle = MakeDataImg.find((v) => v.value === value);
    return (
      <div style={{ display: "flex", alignItems: "center" }} onClick={()=>getFileFromUrl(vehicle?.image,label)}>
        {context === "menu" ? (
          <img
            src={vehicle?.image}
            alt={value}
            style={{ marginRight: "8px", width: "22px",objectFit:'cover' }}
          />
        ) : null}
        {label}
      </div>
    );
  };
  
    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Make ({noOfMake})</h4>
                             </div>
                         </div>
                         {/* table */}
                         <Table handleShowEdit={handleShowEdit} noOfMake={noOfMake} data={data} setPage={setPage} page={page} limit={limit} deletePost={deletePost} />
                         <div onClick={handleShow} className="diverAddBtn"><img src={plus} alt="" /></div>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className="verhicleForm">
                        <div className="closeIco" onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="head">Add Maker Info</div>
                        <div className="form">
                            <div className="form-group">
                                <input onChange={(e) => setIsManual(e.target.checked)} style={{fontSize:15}} type="checkbox" className="border-0 mr-2" placeholder='Company Name'  />
                                <label>Enter Manually</label>
                            </div>
                            {isManual ?
                              <div className="form-group">
                                  <input onChange={(e) => setFormName(e.target.value)} type="text" className="form-control border-0" placeholder='Company Name'  />
                              </div>
                            :
                            <div className="form-group">
                            <Select
                                className="basic-single input"
                                classNamePrefix="select"
                                // defaultValue={optionMake[0]}
                                // isLoading={isLoading}
                                // isClearable={isClearable}
                                isRtl={false}
                                isSearchable={true}
                                name="Make"
                                options={MakeData}
                                formatOptionLabel={formatOptionLabel}
                                placeholder={"Select Maker"}
                                onChange={(e)=>setFormName(e.value)}
                            />
                            </div>
                            }
                            <div className="bb1pxLight"></div>
                            <div className="addLogo">
                                <div className="title">Attach Logo</div>
                                <label>
                                  {formLogo ? 
                                    <img src={URL.createObjectURL(formLogo)} alt="upload" />
                                  :
                                    <img src={upload} alt="upload" />
                                  }
                                    <input onChange={(e) => handleChangeLogo(e)} type="file" name="logo" accept="image/*" />
                                </label>
                            </div>
                            <div className='mt-4'><button className="themeBtn w-100" onClick={handleSubmit} >Submit</button></div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showEdit} onHide={handleCloseEdit} centered >
                <Modal.Body>
                    <div className="verhicleForm">
                        <div className="closeIco" onClick={handleCloseEdit}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="head">Edit Maker</div>
                        <div className="form">
                            <div className="form-group">
                                <input onChange={(e) => setEditData({...editData,"name":e.target.value})} value={editData?.name} type="text" className="form-control border-0" placeholder='Company Name'  />
                            </div>
                            <div className="bb1pxLight"></div>
                            <div className="addLogo">
                                <div className="title">Attach Logo</div>
                                <label>
                                  {editData?.logo ? 
                                  <>
                                  {typeof editData?.logo == "string" ?
                                    <img 
                                    src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${editData?.logo}`} 
                                    alt="upload" />
                                  :
                                  <img src={URL.createObjectURL(editData?.logo)} alt="upload" />
                                  }
                                  </>
                                  :
                                    <img src={upload} alt="upload" />
                                  }
                                    <input onChange={(e) =>handleChangeLogoEdit(e)} type="file" name="logo" accept="image/*" />
                                </label>
                            </div>
                            <div className='mt-4'><button className="themeBtn w-100" onClick={handleEdit} >Submit</button></div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {loader && 
            <LoaderComp /> }
         
      </>
    )
  }
  const Table=({handleShowEdit,data, page, setPage, noOfMake, limit,deletePost})=>{
    // const [data,setData]=useState(driverData)
    const handleChange = (event, value) => {
      setPage(value);
    };

    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table class="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">#</th>
                  <th scope="col" className="text-uppercase">Company Logo</th>
                  <th scope="col" className="text-uppercase">Name</th>
                  <th scope="col" className="text-uppercase">Action</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row,i)=>(

                <tr>
                  <td>
                    <span className="table-text-dark w-max-c db">{i + 1}</span>
                  </td>
                  <td scope="row" className="d-flex">
                        <div className="maker-logo">
                            <img 
                            src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${row.logo}`} 
                            alt="" />
                        </div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row.name}</span>
                  </td>
                  <td>
                    <div className="mr-5">
                      <a href="#" className="mr-3" onClick={()=>handleShowEdit(row)} ><img src={edit} alt="edit" /></a>
                      {/* <a href="#" onClick={()=>deletePost(row?._id)}><img src={Delete} alt="Delete" /></a> */}
                    </div>
                  
                  </td>
                </tr>
                ))}
              
            
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfMake/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>

    </div>
    )
  }

  export default Maker
import toyota from "../../assets/images/toyota.png";
import honda from "../../assets/images/honda.png";
import bmw from "../../assets/images/bmw.png";
import kia from "../../assets/images/kia.png";
import hyundai from "../../assets/images/hyundai.png";
import Mercedes from "../../assets/images/Mercedes.png";
import nissan from "../../assets/images/nissan.svg";
import daihatsu from "../../assets/images/daihatsu.png";
import Changan from "../../assets/images/Changan.jpg";
import Haval from "../../assets/images/Haval.jpg";
import MG from "../../assets/images/MG.jpg";
import Mitsubishi from "../../assets/images/Mitsubishi.jpg";
import Rolls from "../../assets/images/Rolls-Royce.jpg";
import audi from "../../assets/images/audi.png";
import dodge from "../../assets/images/dodge.png";
import ford from "../../assets/images/ford.png";
import ferrari from "../../assets/images/ferrari.png";
import jeep from "../../assets/images/jeep.png";
import lamborghini from "../../assets/images/lamborghini.png";
import lexus from "../../assets/images/lexus.jpg";
import mazda from "../../assets/images/mazda.png";
import tesla from "../../assets/images/tesla.png";
import porsche from "../../assets/images/porsche.png";
import suzuki from "../../assets/images/suzuki.png";

export const MakeData = [
    {value:"toyota",label:"Toyota"},
    {value:"honda",label:"Honda"},
    {value:"suzuki",label:"Suzuki"},
    {value:"kia",label:"Kia"},
    {value:"hyundai",label:"Hyundai"},
    {value:"bmw",label:"Bmw"},
    {value:"mercedes",label:"Mercedes"},
    {value:"nissan",label:"Nissan"},
    {value:"daihatsu",label:"Daihatsu"},
    {value:"changan",label:"Changan"},
    {value:"haval",label:"Haval"},
    {value:"MG",label:"MG"},
    {value:"mitsubishi",label:"Mitsubishi"},
    {value:"rolls-royce",label:"Rolls-Royce"},
    {value:"audi",label:"audi"},
    {value:"dodge",label:"dodge"},
    {value:"ford",label:"ford"},
    {value:"ferrari",label:"ferrari"},
    {value:"jeep",label:"jeep"},
    {value:"lamborghini",label:"lamborghini"},
    {value:"lexus",label:"lexus"},
    {value:"mazda",label:"mazda"},
    {value:"tesla",label:"tesla"},
    {value:"porsche",label:"porsche"},
]
export const MakeDataImg = [
    {value:"toyota",image:toyota},
    {value:"honda",image:honda},
    {value:"suzuki",image:suzuki},
    {value:"kia",image:kia},
    {value:"hyundai",image:hyundai},
    {value:"bmw",image:bmw},
    {value:"mercedes",image:Mercedes},
    {value:"nissan",image:nissan},
    {value:"daihatsu",image:daihatsu},
    {value:"changan",image:Changan},
    {value:"haval",image:Haval},
    {value:"MG",image:MG},
    {value:"mitsubishi",image:Mitsubishi},
    {value:"rolls-royce",image:Rolls},
    {value:"audi",image:audi},
    {value:"dodge",image:dodge},
    {value:"ford",image:ford},
    {value:"ferrari",image:ferrari},
    {value:"jeep",image:jeep},
    {value:"lamborghini",image:lamborghini},
    {value:"lexus",image:lexus},
    {value:"mazda",image:mazda},
    {value:"tesla",image:tesla},
    {value:"porsche",image:porsche},
]

import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";

import edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import plus from "../../assets/images/plus.svg";
import upload from "../../assets/images/uploadIco.svg";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import { optionMake } from '../../utils/optionData';
import api from '../../api';
import { useAlert } from 'react-alert';
import { Pagination, Stack } from '@mui/material';


function Team({match}) {
  const alert = useAlert()
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState({});

  const [data, setData] = useState([]);
  const [noOfModels, setNoOfModels] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (val) => {
    setShowEdit(true)
    setEditData(val)
  };

  const handleData = (e,type) => {
    // console.log(e);
    if(type == "make"){
      setFormData({...formData,"make":e})
    }else{
      setFormData({...formData,[e.target.name]:e.target.value})
    }
  } 
//   const handleDataEdit = (e,type) => {
//     console.log(e);
//     if(type == "make"){
//       setEditData({...editData,"make":e})
//     }else{
//       setEditData({...editData,[e.target.name]:e.target.value})
//     }
//   } 

  const checkValidation = () => {
    if(!formData?.name) {
      return "Please insert model name"
    }
    if(!formData.desig) {
      return "Please insert designation"
    }
    if(!formData.contact) {
      return "Please add contact"
    }
    if(!formData.branch) {
      return "Please select branch"
    }
    return false
  }

  const handleSubmit = async() => {
    try {      
      let checks = checkValidation()
      if(checks){
        return alert.show(checks)
      }

      let payload = {
        name:formData.name,
        desig:formData.desig,
        contact:formData.contact,
        branch:formData.branch
      }   
  
      const response = await api.addTeam(payload);
      // console.log(response);
      if(response?.success){
        handleClose();
        getTeam()
        alert.show("Team Member added.")
      }else{
        alert.show("Team upload failed")
      }
    } catch (error) {
      alert.show("Something went wrong.")
    }
  }  

  
  const deleteTeam = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.deleteTeam(payload)
      console.log("deleted");
      // console.log(userData);
      alert.show("Team deleted.")
      getTeam()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

//   const handleEdit = async() => {
//     try {      

//       let payload = {
//         _id:editData._id,
//         make:editData.make,
//         model:editData.model
//       }   
  
//       const response = await api.editModel(payload);
//       console.log(response);
//       if(response?.success){
//         handleClose();
//         page == 1 ? getModels():setPage(1);
//         alert.show("Model edited successfully.")
//         handleCloseEdit()
//       }else{
//         alert.show("Model update failed")
//       }
//     } catch (error) {
//       alert.show("Something went wrong.")
//     }
//   }

const getTeam = async()=>{
    try {
    
      setLoader(true)
      const postData = await api.getTeam()
      // console.log(postData);
      setData(postData.team || [])
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }
  useEffect(() => {
    getTeam()
  }, [])
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Team ({data?.length || 0})</h4>
                             </div>
                         </div>
                         {/* table */}
                         <Table data={data} deleteTeam={deleteTeam} />
                         <div onClick={handleShow} className="diverAddBtn"><img src={plus} alt="" /></div>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                <Modal show={show} onHide={handleClose} centered >
                  <Modal.Body>
                      <div className="verhicleForm">
                          <div className="closeIco" onClick={handleClose}>
                              <i className="fa-solid fa-xmark"></i>
                          </div>
                          <div className="head">Add Team Member</div>
                          <div className="form">
                              <div className="form-group">
                                  <input onChange={(e) => handleData(e)} name="name" type="text" className="form-control border-0" placeholder='Enter Name...'  />
                              </div>
                              <div className="form-group">
                                  <input onChange={(e) => handleData(e)} name="desig" type="text" className="form-control border-0" placeholder='Enter Designation...'  />
                              </div>
                              <div className="form-group">
                                  <input onChange={(e) => handleData(e)} name="contact" type="number" className="form-control border-0" placeholder='Enter Contact Number...'  />
                              </div>
                            <div className="form-group">
                              <select name="branch" onChange={(e) => handleData(e)} id="" placeholder="Select Branch" className='form-control'>
                                <option value="">Select branch</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                              </div>
                              <div className='mt-4'><button className="themeBtn w-100" onClick={handleSubmit}>Submit</button></div>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>

                {/* <Modal show={showEdit} onHide={handleCloseEdit} centered >
                  <Modal.Body>
                      <div className="verhicleForm">
                          <div className="closeIco" onClick={handleCloseEdit}>
                              <i className="fa-solid fa-xmark"></i>
                          </div>
                          <div className="head">Edit Team Info</div>
                          <div className="form">
                            <div className="form-group">
                              <Select
                                  className="basic-single input"
                                  classNamePrefix="select"
                                  // defaultValue={optionMake[0]}
                                  // isLoading={isLoading}
                                  // isClearable={isClearable}
                                  isRtl={false}
                                  isSearchable={true}
                                  name="Make"
                                  options={makers}
                                  placeholder={"Select Maker"}
                                  onChange={(e)=>handleDataEdit(e.value,"make")}
                              />
                              </div>
                              <div className="form-group">
                                  <input onChange={(e) => handleDataEdit(e)} value={editData?.model} name="model" type="text" className="form-control border-0" placeholder='Enter Model Name...'  />
                              </div>
                              <div className='mt-4'><button className="themeBtn w-100" onClick={handleEdit}>Submit</button></div>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal> */}
          
      </>
    )
  }
  const Table=({data, deleteTeam})=>{

    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table class="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">#</th>
                  <th scope="col" className="text-uppercase">Name</th>
                  <th scope="col" className="text-uppercase">Designation</th>
                  <th scope="col" className="text-uppercase">Contact</th>
                  <th scope="col" className="text-uppercase">Branch</th>
                  <th scope="col" className="text-uppercase">Action</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row,i)=>(

                <tr>
                  <td>
                    <span className="table-text-dark w-max-c db">{i + 1}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.name}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.desig}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.contact}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.branch}</span>
                  </td>
                  <td>
                    <div className="mr-5">
                      {/* <a href="#" className="mr-3" onClick={()=>handleShowEdit(row)}><img src={edit} alt="edit" /></a> */}
                      <a href="#" onClick={()=>deleteTeam(row?._id)}><img src={Delete} alt="Delete" /></a>
                    </div>
                  
                  </td>
                </tr>
                ))}
              
            
              </tbody>
            </table>
          </div>
       
      </div>

    </div>
    )
  }

  export default Team
import React,{useEffect,useState} from 'react';
import { Link, NavLink } from "react-router-dom";

import edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import plus from "../../assets/images/plus.svg";
import { driverData } from "./driversData";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useAlert } from 'react-alert';
import api from '../../api';
import { Pagination, Stack } from '@mui/material';
import { optionActive } from '../../utils/optionData';
import Switch from "react-switch";
import { LoaderComp } from '../../component/loader/loader';
import axios from 'axios';
import { useSelector } from 'react-redux';

function Vehicles({match}) {
  const alert = useAlert()
  const adminStore = useSelector((state) => state.adminReducer);

  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [loader, setLoader] = useState(false);
  const [noOfVehicle, setnoOfVehicle]=useState(0)
  const [editData, setEditData] = useState({});
  const [makers, setMakers] = useState([]);
  const [models, setModels] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (val) => {
    setShowEdit(true)
    setEditData(val)
  };

  const getVehicleData = async()=>{
    try {
      let payload = {
        page:page
      }
      let checkS = await checkSession()
      setLoader(true)
      const postData = await api.getVehicleAdmin(payload)
      // console.log(postData);
      setData(postData.vehicle)
      setLimit(postData.limit)
      setnoOfVehicle(postData.count)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }
  const getMakers = async()=>{
    try {
    
      setLoader(true)
      const postData = await api.getAllMake()
      // console.log(postData);
      if(postData.success){
        let data = postData.make.map((d)=> (
          {value:d._id,label:d.name}
        ));
        // console.log(data);
        setMakers(data)
      }else {
      alert.show("Something went wrong.")
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const getModels = async()=>{
    try {
      setLoader(true)
      const postData = await api.getAllModels()
      // console.log(postData);
      let data = postData.model.map((d)=> (
        {value:d._id,label:d.model}
      ));
      setModels(data)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  useEffect(() => {
    getVehicleData()
  
  }, [page])

  const checkSession = async(token)=> {
    try {
      let payload = {
        token:token
      }
      const res = await api.checkSession(payload) 
      // console.log(res);
      if(res?.token) {
        axios.defaults.headers.common["Authorization"] = res?.token;
        return true
      }else{
        // logout()
        return false
      }
    } catch (error) {
      console.log(error);
      return false
    }
  }

  useEffect(() => {
    getMakers()
    getModels()
  }, [])

  // console.log(selectedFile);
  // console.log(editData);

  const handleDataEdit = (e,type) => {
    // console.log(e);
    if(type){
      setEditData({...editData,[type]:e})
      // console.log(type);
      
    }else{
      setEditData({...editData,[e.target.name]:e.target.value})
    }
  } 

  const handleEdit = async() => {
    try {      

      let payload = {
        _id:editData._id,
        make:editData.make,
        model:editData.model,
        title:editData.title,
        seller:editData.seller,
        status:editData.status,
      }   
      setLoader(true)
      
      const response = await api.editVehicle(payload);
      // console.log(response);
      if(response?.success){
        page == 1 ? getVehicleData():setPage(1);
        alert.show("Vehicle updated successfully.")
        handleCloseEdit()
        setLoader(false)
      }else{
        alert.show("Vehicle update failed")
        setLoader(false)
      }
    } catch (error) {
      alert.show("Something went wrong.")
      setLoader(false)
    }
  }

  const deleteVehicle = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.vehicleDelete(payload)
      console.log("deleted");
      // console.log(userData);
      alert.show("Vehicle deleted.")
      getVehicleData()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }



    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Vehicles ({noOfVehicle})</h4>
                             </div>
                             {/* <div className="col-xl-6 col-md-5 jc-fe d-flex">
                                <div class="dropdown dib">
                                    <a class="btn dropdown-toggle border-white bg-white color-dark font-weight-bold fs-12" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Start Date
                                    </a>
                                    <div class="dropdown-menu zi-1" aria-labelledby="dropdownMenu2">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                    </div>
                                </div>
                                <label className="ml-2 btn border-transparent bg-white color-dark font-weight-bold fs-12">+ Upload File
                                  <input type="file" value="" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                </label> 
                             </div> */}
                         </div>
                         {/* table */}
                         <Table handleShowEdit={handleShowEdit} noOfVehicle={noOfVehicle} data={data} setPage={setPage} page={page} limit={limit} deleteVehicle={deleteVehicle} />
                         <NavLink to={"/admin/add-vehicle"}  className="diverAddBtn"><img src={plus} alt="" /></NavLink>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}

                  <Modal show={showEdit} onHide={handleCloseEdit} centered >
                  <Modal.Body>
                      <div className="verhicleForm">
                          <div className="closeIco" onClick={handleCloseEdit}>
                              <i className="fa-solid fa-xmark"></i>
                          </div>
                          <div className="head">Edit Vehicle Info</div>
                          <div className="form">
                            <div className="form-group d-flex jc-sb">
                              <div>Status</div>
                              <div>
                                <Switch onChange={(e)=>handleDataEdit(e,"status")} checked={editData?.status} 
                                />
                              </div>
                            </div>
                            <div className="form-group">
                                  <input onChange={(e) => handleDataEdit(e)} value={editData?.seller} name="seller" type="text" className="form-control border-0" placeholder='Enter Seller Name...'  />
                              </div>
                            <div className="form-group">
                                  <input onChange={(e) => handleDataEdit(e)} value={editData?.title} name="title" type="text" className="form-control border-0" placeholder='Enter Title Name...'  />
                              </div>
                            <div className="form-group">
                              <Select
                                  className="basic-single input"
                                  classNamePrefix="select"
                                  // defaultValue={optionMake[0]}
                                  // isLoading={isLoading}
                                  // isClearable={isClearable}
                                  isRtl={false}
                                  isSearchable={true}
                                  name="Make"
                                  options={makers}
                                  placeholder={"Update Maker"}
                                  onChange={(e)=>handleDataEdit(e.value,"make")}
                              />
                              </div>
                              <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        // defaultValue={editData?.model}
                                        // isLoading={isLoading}
                                        // isClearable={isClearable}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Model"
                                        options={models}
                                        placeholder={"Update Model"}
                                        onChange={(e)=>handleDataEdit(e.value,"model")}

                                    />
                                </div>
                             
                              <div className='mt-4'><button className="themeBtn w-100" onClick={handleEdit}>Submit</button></div>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
         
                {loader &&
                  <LoaderComp /> 
                  }
      </>
    )
  }
  const Table=({handleShowEdit, noOfVehicle, data, setPage, limit, page,deleteVehicle})=>{
    // const [data,setData]=useState(driverData)
    const handleChange = (event, value) => {
      setPage(value);
    };
    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table class="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">Thumbnail</th>
                  <th scope="col" className="text-uppercase">Seller</th>
                  <th scope="col" className="text-uppercase">Country</th>
                  <th scope="col" className="text-uppercase">Title</th>
                  <th scope="col" className="text-uppercase">Make</th>
                  <th scope="col" className="text-uppercase">Model</th>
                  <th scope="col" className="text-uppercase">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((row,i)=>(

                <tr>
                  <td scope="row" className="d-flex">
                    <div className='maker-logo'>
                        <img 
                        src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${row?.images[0]}`} 
                        alt="" />                    
                    </div>
                        {/* <section>
                          <div className="table-text-dark">{row.driverName}</div>
                          <div className="table-text-light">{row.driverEmail}</div>
                        </section> */}
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{row?.seller ? row?.seller:"-"}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{row?.country ? row?.country:"-"}</span>
                  </td>
                  <td>
                    <span className="table-text-dark db max-width-400">{row.title}</span>
                  </td>
                  <td className="">
                    <span className="table-text-dark text-uppercase">{row?.makerDetails?.name}</span>
                  </td>
                  <td className="">
                    <span className="table-text-dark text-uppercase">{row?.modelDetails?.model}</span>
                  </td>
                  <td>
                    {/* <span className="status-loaded ">{row.status}</span> */}
                    <span className=
                    {row.status == true && row.status != false 
                    ? "status-loaded driver-status-title w-max-c"
                    :row.status == false && row.status != true
                    ?"status-waiting driver-status-title w-max-c"
                    :"-"}>
                    {row?.status ?"Active":"In-active"}</span>
                  </td>
                  <td>
                    <div className="mr-5">
                      <Link to={`/admin/edit-vehicle/${row?.stockId}`} className="mr-3" ><img src={edit} alt="edit" /></Link>
                      {/* <a href="#" className="mr-3" onClick={()=>handleShowEdit(row)}><img src={edit} alt="edit" /></a> */}
                      <a href="#" onClick={()=>deleteVehicle(row?._id)}><img src={Delete} alt="Delete" /></a>
                    </div>
                  
                  </td>
                </tr>
                ))}
              
            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfVehicle/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>
    </div>
    )
  }

  export default Vehicles
import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";

import edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import plus from "../../assets/images/plus.svg";
import upload from "../../assets/images/uploadIco.svg";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import { modelData } from "./modelData";
import { optionMake } from '../../utils/optionData';
import api from '../../api';
import { useAlert } from 'react-alert';
import { Pagination, Stack } from '@mui/material';


function Models({match}) {
  const alert = useAlert()
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [makers, setMakers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState({});

  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [data, setData] = useState([]);
  const [noOfModels, setNoOfModels] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (val) => {
    setShowEdit(true)
    setEditData(val)
  };

  const handleData = (e,type) => {
    // console.log(e);
    if(type == "make"){
      setFormData({...formData,"make":e})
    }else{
      setFormData({...formData,[e.target.name]:e.target.value})
    }
  } 
  const handleDataEdit = (e,type) => {
    // console.log(e);
    if(type == "make"){
      setEditData({...editData,"make":e})
    }else{
      setEditData({...editData,[e.target.name]:e.target.value})
    }
  } 

  const checkValidation = () => {
    if(!formData?.model) {
      return "Please insert model name"
    }
    if(!formData.make) {
      return "Please select maker"
    }
    return false
  }

  const handleSubmit = async() => {
    try {      
      let checks = checkValidation()
      if(checks){
        return alert.show(checks)
      }

      let payload = {
        make:formData.make,
        model:formData.model
      }   
      setLoader(true)
      
      const response = await api.addModel(payload);
      // console.log(response);
      if(response?.success){
        handleClose();
        page == 1 ? getModels():setPage(1);
        setLoader(false)
        alert.show("Model successfully uploaded.")
      }else{
        setLoader(false)
        alert.show("Model upload failed")
      }
    } catch (error) {
      setLoader(false)
      alert.show("Something went wrong.")
    }
  }  

  const getMakers = async()=>{
    try {
    
      setLoader(true)
      const postData = await api.getAllMake()
      // console.log(postData);
      if(postData.success){
        let data = postData.make.map((d)=> (
          {value:d._id,label:d.name}
        ));
        // console.log(data);
        setMakers(data)
      }else {
      alert.show("Something went wrong.")
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const getModels = async()=>{
    try {
      let payload = {
        page:page
      }
      setLoader(true)
      const postData = await api.getModels(payload)
      // console.log(postData);
      setData(postData.model)
      setLimit(postData.limit)
      setNoOfModels(postData.count)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }
  
  const deleteModel = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.modelDelete(payload)
      console.log("deleted");
      // console.log(userData);
      alert.show("Model deleted.")
      getModels()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleEdit = async() => {
    try {      

      let payload = {
        _id:editData._id,
        make:editData.make,
        model:editData.model
      }   
      setLoader(true)
      
      const response = await api.editModel(payload);
      // console.log(response);
      if(response?.success){
        handleClose();
        page == 1 ? getModels():setPage(1);
        alert.show("Model edited successfully.")
        handleCloseEdit()
      setLoader(false)
      
    }else{
        setLoader(false)
        alert.show("Model update failed")
      }
    } catch (error) {
      setLoader(false)
      alert.show("Something went wrong.")
    }
  }

  useEffect(() => {
    getMakers()
  }, [])

  useEffect(() => {
    getModels()
  
  }, [page])
  
// console.log(editData);

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Model ({noOfModels})</h4>
                             </div>
                         </div>
                         {/* table */}
                         <Table handleShowEdit={handleShowEdit} noOfModels={noOfModels} data={data} setPage={setPage} page={page} limit={limit} deleteModel={deleteModel} />
                         <div onClick={handleShow} className="diverAddBtn"><img src={plus} alt="" /></div>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                <Modal show={show} onHide={handleClose} centered >
                  <Modal.Body>
                      <div className="verhicleForm">
                          <div className="closeIco" onClick={handleClose}>
                              <i className="fa-solid fa-xmark"></i>
                          </div>
                          <div className="head">Add Model Info</div>
                          <div className="form">
                            <div className="form-group">
                              <Select
                                  className="basic-single input"
                                  classNamePrefix="select"
                                  // defaultValue={optionMake[0]}
                                  // isLoading={isLoading}
                                  // isClearable={isClearable}
                                  isRtl={false}
                                  isSearchable={true}
                                  name="Make"
                                  options={makers}
                                  placeholder={"Select Maker"}
                                  onChange={(e)=>handleData(e.value,"make")}
                              />
                              </div>
                              <div className="form-group">
                                  <input onChange={(e) => handleData(e)} name="model" type="text" className="form-control border-0" placeholder='Enter Model Name...'  />
                              </div>
                              <div className='mt-4'><button className="themeBtn w-100" onClick={handleSubmit}>Submit</button></div>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>

                <Modal show={showEdit} onHide={handleCloseEdit} centered >
                  <Modal.Body>
                      <div className="verhicleForm">
                          <div className="closeIco" onClick={handleCloseEdit}>
                              <i className="fa-solid fa-xmark"></i>
                          </div>
                          <div className="head">Edit Model Info</div>
                          <div className="form">
                            <div className="form-group">
                              <Select
                                  className="basic-single input"
                                  classNamePrefix="select"
                                  // defaultValue={optionMake[0]}
                                  // isLoading={isLoading}
                                  // isClearable={isClearable}
                                  isRtl={false}
                                  isSearchable={true}
                                  name="Make"
                                  options={makers}
                                  placeholder={"Select Maker"}
                                  onChange={(e)=>handleDataEdit(e.value,"make")}
                              />
                              </div>
                              <div className="form-group">
                                  <input onChange={(e) => handleDataEdit(e)} value={editData?.model} name="model" type="text" className="form-control border-0" placeholder='Enter Model Name...'  />
                              </div>
                              <div className='mt-4'><button className="themeBtn w-100" onClick={handleEdit}>Submit</button></div>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
          
      </>
    )
  }
  const Table=({handleShowEdit, noOfModels, data, setPage, limit, page,deleteModel})=>{

    const handleChange = (event, value) => {
      setPage(value);
    };
    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table class="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">#</th>
                  <th scope="col" className="text-uppercase">Company Logo</th>
                  <th scope="col" className="text-uppercase">Make</th>
                  <th scope="col" className="text-uppercase">Model</th>
                  <th scope="col" className="text-uppercase">Action</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((row,i)=>(

                <tr>
                  <td>
                    <span className="table-text-dark w-max-c db">{i + 1}</span>
                  </td>
                  <td scope="row" className="d-flex">
                    <div className="maker-logo">
                        <img 
                        src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${row?.makerDetails?.logo}`} 
                        alt="" />
                    </div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.makerDetails?.name}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db text-uppercase">{row?.model}</span>
                  </td>
                  <td>
                    <div className="mr-5">
                      <a href="#" className="mr-3" onClick={()=>handleShowEdit(row)}><img src={edit} alt="edit" /></a>
                      {/* <a href="#" onClick={()=>deleteModel(row?._id)}><img src={Delete} alt="Delete" /></a> */}
                    </div>
                  
                  </td>
                </tr>
                ))}
              
            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfModels/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>

    </div>
    )
  }

  export default Models
import React from 'react'
import logo from "../../assets/images/logo.png";

export const AlertModal = ({msg}) => {
  return (
    <div className="alertModelMain">
        <div className='alertModel'>
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="msg">{msg}</div>
        </div>
    </div>
  )
}
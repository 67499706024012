/* eslint-disable */
import $ from 'jquery';

function animate(params) {

    window.addEventListener('scroll', () => {
        const scrollable = document.documentElement.scrollHeight - window.innerHeight; //total no of line
        const scrolled = window.scrollY; //specific no of line where we have ,when scoll it increase
        // console.log(Math.ceil(scrolled))
        // if (Math.ceil(scrolled) > 548) {
          
        //     document.getElementById('scrollToTop').style.display = 'inline';
        // } else {
        //     document.getElementById('scrollToTop').style.display = 'none';
        // }
        if (Math.ceil(scrolled) > 260) {
            if(document.getElementById('smNav')){
                document.getElementById('smNav').classList.add('addMargin');
            }
            document.getElementById('navbar').classList.add('navStick');
        } else {
            if(document.getElementById('smNav')){
                document.getElementById('smNav').classList.remove('addMargin');
            }
            document.getElementById('navbar').classList.remove('navStick');
        }
    });


     // Function which adds the 'animated' class to any '.animatable' in view
     var doAnimations = function() {
        "use strict";
          
            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');
            
            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
              $(window).off('scroll', doAnimations);
            }
            console.log("run");
            // Check all animatables and animate them if necessary
                $animatables.each(function(i) {
               var $animatable = $(this);
                    // if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    if (($animatable.offset().top + 110) < offset) {
                $animatable.removeClass('animatable').addClass('animated');
                    }
            });
        
            };

      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);


}

export {animate};

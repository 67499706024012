import React,{useEffect} from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';

// import profile from "../../../images/profile.jpg";
import logo from "../../assets/images/logo.svg";
import "../styles.css"

function Sidebar() {

  useEffect(()=>{
    sidebarFunctionality()
  })

    const sidebarFunctionality=()=>{
      /* eslint-disable */
        "use strict";
        $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
            $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide");
        }),
        $(window).resize(function () {
          $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
          $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").collapse("hide"));
            }),
            $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
                var o;
                768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
            }),
            $(document).on("scroll", function () {
                100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
            });
           
    }
      

    return(
        <>

             {/* Sidebar */}
          <ul className="navbar-nav shadow sidebar shadow sidebar-dark accordion zi-1 pb-100" id="accordionSidebar">
            {/* Sidebar - Brand */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-2" to="/">
              
              <div className="sidebar-brand-text mx-3">
                <img src={logo} className="w-100" alt="apollo" />
              </div>
              <div className="mini mx-3">
                <img src={logo} className="w-100" alt="apollo" />
              </div>
            </Link>
           
            {/* sidebar links */}
            {/* <li className="nav-item my-1 mt-5">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/dashboard">
                <i className="fa-solid fa-house"></i>                
                <span className="">Dashboard</span></NavLink>
            </li> */}
            <li className="nav-item my-1 mt-5">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/make">
              <i className="fa-solid fa-car-tunnel"></i>
              <span className="">Make</span></NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/model">
              {/* <img className="" src={home} alt="" /> */}
                <i className="fa-solid fa-car-on"></i>
                <span className="">Model</span></NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/vehicles">
                <i className="fa-solid fa-car"></i>
                <span className="">Vehicle</span></NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/review">
                <i className="fa-solid fa-car"></i>
                <span className="">Review</span></NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/team">
                <i className="fa-solid fa-user"></i>
                <span className="">Team</span></NavLink>
            </li>
            {/* <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/reciept">
                <i className="fa-solid fa-user"></i>
                <span className="">Sale Reciept</span></NavLink>
            </li> */}
          
            {/* Nav Item - Pages Collapse Menu */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog" />
                <span>Components</span>
              </a>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
            </li> */}
           
       
          </ul>
          {/* End of Sidebar */}

        </>
    )
    
}
export default Sidebar
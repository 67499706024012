import React, { useState } from 'react'
import logo from "../assets/images/logo.svg"
import { Header } from '../component/header/header'
import adminAction from "../redux/admin/action";
import { useDispatch } from 'react-redux';
import api from '../api';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const alert = useAlert();
    const navigate = useNavigate();

    const [id,setId]=useState("")
    const [pass,setPass]=useState("")
  const [loader, setLoader] = useState(false);
    
    const { setAdmin } = adminAction;
    const dispatch = useDispatch()

    const validators = () => {
        if (!id) {
          return "Email is required";
        }
        if (!pass) {
          return "Password is required";
        }
        return false;
      };

    const handleSubmit = async(e) => {
        e.preventDefault();

        setLoader(true);
    
        try {
          const validatorsResponse = await validators();
    
          if (validatorsResponse) {
            throw new Error(validatorsResponse);
          }
    
          const payload = {
            email: id,
            password: pass,
          };
          const registerationResponse = await api.adminLogin(payload);
    
          axios.defaults.headers.common["Authorization"] =
            registerationResponse.token;
    
    
          localStorage.setItem("token", registerationResponse.token);
    
          dispatch(setAdmin(registerationResponse));
          navigate("/admin/make");
          setLoader(false);
        } catch (e) {
          alert.show(e.message);
          // alert.show("Invalid Credentials or Account not verified");
          setLoader(false);
        }
    }

  return (
    <>
    <Header />
    <div className='loginPage'>
        <form className='formCard' onSubmit={handleSubmit}>
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="form-group">
                <input onChange={(e)=>setId(e.target.value)} type="email" className="form-control" placeholder='Email id ...' />
            </div>
            <div className="form-group">
                <input onChange={(e)=>setPass(e.target.value)} type="password" className="form-control" placeholder="Password ..." />
            </div>
            <button className="themeBtn" >Login</button>
        </form>
    </div>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import "./header.css";
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo.svg";
import pakistan from "../../assets/images/pakistan.svg";
import uae from "../../assets/images/uae.svg";
import { AlertModal } from '../alertModal/alertModal';
import animate from "../../assets/js/animate"

export const Header = () => {
    const [alertMsg,setAlertMsg]=useState("")
    const [alertShow,setAlertShow]=useState(false);

    const HandleAlert = () => {
        setAlertShow(true)
        setAlertMsg("Coming Soon")
        setTimeout(() => {
            setAlertShow(false)
            setAlertMsg("Coming Soon")
        }, 3000);
    }

    // useEffect(() => {
    //     animate()
    // }, [])
    

  return (
    <>
    <header>
        <div className="smHeader" id='smNav'>
            <div className="container">
                <div className="innerRow">
                    <div className="col1">
                        <div className="contact">
                            <i className="fa-solid fa-phone"></i>                
                            <a href="tel:+923363929249">+92-336-3929249</a>
                        </div>
                        <div className="contact">
                            <i className="fa-solid fa-envelope"></i> 
                            <a href="mailto:info@pakautosource.co">info@pakautosource.co</a>
                        </div>
                        <div className="contact">
                            <i className="fa-solid fa-clock"></i>                    
                            9AM - 9PM
                        </div>
                    </div>
                    <div className="socialMain">
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-x-twitter"></i>                        
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-instagram"></i>            
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-linkedin-in"></i>            
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-youtube"></i>            
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg " id="navbar">
            <div className="container z-1 pr">
                <Link className="navbar-brand" to="/">
                    <img className='img-fluid' src={Logo} alt="" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fa-solid fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                    <Link className="nav-link" to="/">Home </Link>
                    </li>
                    <li className="nav-item dropdown">
                        {/* <Link className="nav-link" to="/inventory">Global Stock</Link> */}
                        <a className="nav-link" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Global Stock</a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item px-3 flag" type="button" to={"/stock/pakistan"}>
                                <img src={pakistan} alt=""  />PAKISTAN
                            </Link>
                            <Link className="dropdown-item px-3 flag" type="button" to={"/stock/uae"}>
                                <img src={uae} alt=""  />
                                UAE
                            </Link>
                        </div>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/about">About</Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Dropdown
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                    </li> */}
                
                </ul>
                    {/* <Link className="themeBtn" to={"/contact"} >Contact Us</Link> */}
                    <div className="btn-group contactDrop">
                        <button type="button" className="themeBtn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Contact Us
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <Link className="dropdown-item px-3 flag" type="button" to={"/contact"}>
                                <img src={pakistan} alt=""  />PAKISTAN
                            </Link>
                            <Link className="dropdown-item px-3 flag" type="button" to={"/contact-uae"}>
                                <img src={uae} alt=""  />
                                UAE
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        
    </header>
    {alertShow &&
    <AlertModal msg={alertMsg} />
    }
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Header } from '../component/header/header'
import { PiEngineFill } from "react-icons/pi";
import { FaRoad } from "react-icons/fa";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { TbManualGearboxFilled } from "react-icons/tb";
import { GiCarWheel, GiH2O } from "react-icons/gi";
import { IoContrast } from "react-icons/io5";
import { IoIosPaper } from "react-icons/io";
import { FaHashtag } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

import ImageGallery from "react-image-gallery";
import api from '../api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoaderComp } from '../component/loader/loader';

export const DetailPage = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate({});
  const [dataImages, setDataImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [noData, setNoData] = useState(false);
  const params = useParams();
  // console.log(params);
  
  const getVehicleData = async()=>{
    try {
      
      setLoader(true)
      const postData = await api.getVehicleByStockId(params.id)
      // console.log(postData);
      setData(postData.vehicle)
      if(!postData.vehicle){
        setNoData(true)
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
      setNoData(true)
    }
  }

  useEffect(() => {
    getVehicleData()
  }, [])
  useEffect(() => {
    if(data?.images) {
      let tempArr = [];
      // console.log(data?.images);
      
      data?.images.forEach((image, index) => {
        let tempImg = {
          'original': `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${image}`,
          'thumbnail': `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${image}`,
                      } 
        tempArr.push(tempImg);
      });
      setDataImages(tempArr)
      
      document.querySelector('meta[name="description"]').content = data?.title;

      document.querySelector('meta[property="og:url"]').content = window.location.href;
      document.querySelector('meta[property="og:title"]').content = data?.title;
      document.querySelector('meta[property="og:description"]').content = data?.title;
      document.querySelector('meta[property="og:image"]').content = `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${data?.images[0]}`;
      
      document.querySelector('meta[name="twitter:description"]').content = data?.title;
      document.querySelector('meta[name="twitter:image"]').content = `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${data?.images[0]}`;
      
      document.querySelector('meta[itemprop="description"]').content = data?.title;
      document.querySelector('meta[itemprop="image"]').content = `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${data?.images[0]}`;
    }
  }, [data])

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${Number.parseFloat((value / 10000000).toFixed(2))} Cr`
    if (val >= 100000) return `${Number.parseFloat((value / 100000).toFixed(2))} Lac`
    return value;
  }


   
  return (
    <div className='detailPage'>
      <Header />
      {noData ?
      <div className="noDataMain">
        <h1>No Data Available</h1>
      </div>
      :
      <div className="detailPageMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="col1">
                <div className="row1">
                  <div className="name">{data?.title}</div>
                  {data?.price == 0 ?
                  <div className="price">Call For Price</div>
                  :
                  <>
                  {data?.country == "UAE" ?
                  <div className="price">{data?.price} AED</div>
                  :
                  <div className="price">Rs {numDifferentiation(data?.price)}</div>
                  }
                  </>
                  }
                </div>
                <ShareSocialBtn data={data} dataImages={dataImages} />
                <div className={data?.waterMark ?"galleryMain addWaterMark":"galleryMain"} >
                  <ImageGallery items={dataImages} showThumbnails={true} showNav={true} />
                </div>
                <div className="functionMain">
                  <ul class="data-list-wrap cols-3 tablet-cols-2 mobile-cols-1">
                      <li class="data-list-item">
                        <span class="item-label">
                          <FaHashtag />
                            Stock id						
                        </span>
                        <span class="heading-font" title="553095">
                          {data?.stockId}													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <i class="fas fa-truck-monster"></i>Body
                        </span>
                        <span class="heading-font" title="Off-Road">
                          {data?.bodyType}
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <i class="fas fa-car"></i>Make						
                        </span>
                        <span class="heading-font text-uppercase" title="Ford">
                            {data?.make?.name}													
                        </span>
                      </li>
                      <li class="data-list-item">
                          <span class="item-label">
                            <i class="fas fa-car-side"></i>Model						
                          </span>
                          <span class="heading-font text-uppercase" title="Explorer">
                            {data?.model?.model}													
                          </span>
                      </li>
                      <li class="data-list-item">
                          <span class="item-label">
                            <i class="fas fa-car-side"></i>Manufacturer						
                          </span>
                          <span class="heading-font" title="Explorer">
                            {data?.manufacturer}													
                          </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <TbManualGearboxFilled />
                          Transmission						
                        </span>
                        <span class="heading-font" title="Automatic">
                          {data?.transmission}													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <i className="fa-solid fa-gas-pump"></i>
                          Fuel type						
                        </span>
                        <span class="heading-font" title="Diesel">
                          {data?.fuelType}													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <PiEngineFill />
                          Engine						
                        </span>
                        <span class="heading-font" title="4700">
                          {data?.engine}													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <i class="fa-solid fa-road ml-1"></i>Year						
                        </span>
                        <span class="heading-font" title="2015">
                          {data?.year}													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                        <i class="fa-solid fa-road ml-1"></i>                               
                        Mileage						
                        </span>
                        <span class="heading-font" title="38000 mi">
                          {data?.mileage} mi													
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <GiCarWheel />Drive						
                        </span>
                        <span class="heading-font" title="4WD">
                          {data?.drive}
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <GiCarWheel />Condition						
                        </span>
                        <span class="heading-font" title="4WD">
                          {data?.condition}
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <IoContrast />
                          Exterior Color						
                        </span>
                    <span class="heading-font" title="Pearl White">
                              {data?.exterior}												
                              </span>
                          </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <IoContrast />
                          Interior Color						
                        </span>
                        <span class="heading-font" title="Jet Black">
                          {data?.interior}											
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <IoContrast />
                          Grade						
                        </span>
                        <span class="heading-font" title="Jet Black">
                          {data?.grade ? data?.grade : "-"}											
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <IoContrast />
                          Package						
                        </span>
                        <span class="heading-font" title="Jet Black">
                          {data?.package ? data?.package : "-"}											
                        </span>
                      </li>
                      <li class="data-list-item">
                        <span class="item-label">
                          <IoIosPaper />
                          Registered						
                        </span>
                        <span class="heading-font" title="N/A">
                          {data?.registered}									
                        </span>
                      </li>
                  </ul>
                </div>
                <div className="featureMain">
                  <div className="heading">Features</div>
                  <div className="fRow">
                    <div className="fCol">
                      <h4>Compfort</h4>
                      {data?.comfort && data?.comfort[0].split(',').map((item,key)=>(
                        item &&
                      <div className="FeatureRow" key={key}>
                        <div className="ico"><i className="fa-solid fa-circle-check"></i></div>
                        <span>{item}</span>
                      </div>
                      ))}
                    </div>
                    <div className="fCol">
                      <h4>Entertainment</h4>
                      {data?.entertainment && data?.entertainment[0].split(',').map((item,key)=>(
                        item &&
                      <div className="FeatureRow">
                        <div className="ico"><i className="fa-solid fa-circle-check"></i></div>
                        <span>{item}</span>
                      </div>
                      ))}
                    </div>
                    <div className="fCol">
                      <h4>Safety</h4>
                      {data?.safety && data?.safety[0].split(',').map((item,key)=>(
                        item &&
                      <div className="FeatureRow" key={key}>
                        <div className="ico"><i className="fa-solid fa-circle-check"></i></div>
                        <span>{item}</span>
                      </div>
                      ))}
                    </div>
                    <div className="fCol">
                      <h4>Seats</h4>
                      {data?.seats && data?.seats[0].split(',').map((item,key)=>(
                        item &&
                      <div className="FeatureRow">
                        <div className="ico"><i className="fa-solid fa-circle-check"></i></div>
                        <span>{item}</span>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="descMain">
                  <div className="heading">Description</div>
                  <div className="description">
                    <div dangerouslySetInnerHTML={{__html: data?.description }} /> 
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col2">
                <a href={`tel:${data?.phone}`} className="phone">
                  <div className="ico">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  {data?.phone}
                </a>
                {data?.phone &&
                <a className='whatsapp' href={`https://api.whatsapp.com/send/?phone=${data?.phone.indexOf('+') > -1 ? data?.phone.slice(1) : data?.phone}&text&type=phone_number&app_absent=0`}>
                  <div className="ico"><IoLogoWhatsapp /></div>
                  Chat With Whatsapp
                </a>
                }
                {/* <a className='whatsapp email' href="https://api.whatsapp.com/send/?phone=${data?.whatsapp}&text&type=phone_number&app_absent=0">
                  <div className="ico"><MdEmail /></div>
                  Message to Dealer
                </a> */}
                <div className="location">
                  <div className="ico">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  {data?.location}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      }
      {loader && 
      <LoaderComp />}
    </div>
  )
}

const ShareSocialBtn = ({data,dataImages}) => {
  const [shareModal, setShareModal] = useState(false);

  return(
    <>
    <div className="shareMain" onClick={()=>setShareModal(true)}>
      <i className="fa-solid fa-share-nodes"></i>
    </div>
    {shareModal && 
    <div className="shareModalMain">
      <div className="shareModal">
        <div className="closeIco" onClick={()=>setShareModal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <WhatsappShareButton
          url={window.location.href}
          title={data?.title}
          imageUrl={dataImages.length > 0 ? dataImages[0] : ""}
          imgAlt="image"
        >
          <div className="d-flex ai-center">
            <WhatsappIcon size={30} round={true} />
            <div className="text ml-2">Share with whatsapp</div>
          </div>
        </WhatsappShareButton>
      </div>
    </div>
    }
    </>
  )
}

import axios from "./axios";
const api = {};

api.adminLogin = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/login`;
    return axios.post(url, body);
};

api.verifySession = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/check/session`;
    return axios.post(url, body);
};
api.uploadFile = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;
    return axios.post(url, body);
};
api.addMake = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/add/make`;
    return axios.post(url, body);
};
api.editMake = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/edit/make`;
    return axios.post(url, body);
};
api.getMake = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/make`;
    return axios.post(url, body);
};
api.getAllMake = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/all/make`;
    return axios.post(url, body);
};
api.makeDelete = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete/make`;
    return axios.post(url, body);
};
api.addModel = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/add/model`;
    return axios.post(url, body);
};
api.editModel = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/edit/model`;
    return axios.post(url, body);
};
api.getAllModels = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/all/models`;
    return axios.post(url, body);
};
api.getModels = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/model`;
    return axios.post(url, body);
};
api.modelDelete = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete/model`;
    return axios.post(url, body);
};
api.vehicleDelete = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete/vehicle`;
    return axios.post(url, body);
};
api.editVehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/edit/vehicle`;
    return axios.post(url, body);
};
api.addVehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/add/vehicle`;
    const herders={headers: { "Content-Type": "multipart/form-data" }}
    return axios.post(url, body,herders);
};
api.getVehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/vehicle`;
    return axios.post(url, body);
};
api.getVehicleUAE = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/uae/vehicle`;
    return axios.post(url, body);
};
api.getVehicleAdmin = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/vehicle-admin`;
    return axios.post(url, body);
};
api.getFeaturedVehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/featured/vehicle`;
    return axios.post(url, body);
};
api.getVehicleByStockId = function (stockId) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stock/${stockId}`;
    return axios.get(url);
};
api.getHomeStats = function () {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/homestats`;
    return axios.get(url);
};
api.getHomeMaker = function () {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/homemake`;
    return axios.get(url);
};
api.getfileteredVehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stock/filter`;
    return axios.post(url, body);
};
api.addReview = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/add/review`;
    return axios.post(url, body);
};
api.getReview = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/review`;
    return axios.post(url, body);
};
api.deleteReview = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete/review`;
    return axios.post(url, body);
};
api.checkSession = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/check/session`;
    return axios.post(url, body);
};

api.addTeam = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/add/team`;
    return axios.post(url, body);
};
api.deleteTeam = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete/team`;
    return axios.post(url, body);
};
api.getTeam = function () {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get/team`;
    return axios.get(url);
};
api.uploadImages = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/upload/edit/images`;
    return axios.post(url, body);
};

  export default api;
